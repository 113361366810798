import React from 'react';
import { hot } from 'react-hot-loader';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { P2 } from '../../styles';
import { StyledH3, Fineprint } from './Modal.style';
import HotSpotModalList from './HotSpotModalList';

const Container = styled.div``;

const Headline = styled(StyledH3)`
  color: #191919;
`;

const Copy = styled(P2)`
  margin: 0;
  color: #6e6e6e;
`;

class HotSpotModalTextBlock extends React.Component {
  static propTypes = {
    headline: PropTypes.string,
    copy: PropTypes.string,
    list: PropTypes.array,
    fineprint: PropTypes.string,
  };

  render() {
    const { headline, copy, list, fineprint } = this.props;
    return (
      <Container>
        {headline && <Headline>{headline}</Headline>}
        <Copy>{copy}</Copy>
        {list && list.length > 0 && <HotSpotModalList listItems={list} />}
        {fineprint && <Fineprint>{fineprint}</Fineprint>}
      </Container>
    );
  }
}

export default hot(module)(HotSpotModalTextBlock);
