import * as NAVIGATION from '../constants/navigationConstants';
import * as DATA from '../constants/dataConstants';

const initialState = {
  currentLanguage: 'mmuk',
  languages: [],
  steps: [
    {
      title: 'Loading',
      id: 'loading',
      type: 'NAVIGATION.STEP_TYPE_LOADING',
      active: true,
      visited: false,
      showNavigation: false,
      showSideBar: false,
    },
  ],
};

function goToStep(steps, targetStep) {
  return [...steps].map((step, index) => {
    step.active = index == targetStep;
    if (step.active && step.visited !== undefined) step.visited = true;
    return step;
  });
}

function returnToIdle(steps) {
  return [...steps].map(step => {
    if (step.type === NAVIGATION.STEP_TYPE_IDLE) {
      step.active = true;
    } else {
      step.active = false;
      step.visited = false;
      // reset selected image
      if (step.stage && step.stage.images) {
        step.stage.images.map((image, index) => {
          image.selected = index === 0;
        });
      }
    }
    return step;
  });
}

function selectImageByIndex(steps, imageIndex) {
  return [...steps].map(step => {
    if (step.active) {
      step.stage.images.map((image, index) => {
        image.selected = imageIndex === index;
      });
    }
    return step;
  });
}

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case DATA.DATA_STEPS_LOAD_FULFILLED:
      return {
        ...state,
        languages: payload,
      };
    case NAVIGATION.SELECT_LANGUAGE:
      return {
        ...state,
        currentLanguage: payload,
        steps: JSON.parse(JSON.stringify(state.languages.find(language => language.language == payload).steps)),
      };
    case NAVIGATION.GO_TO_INDEX:
      return {
        ...state,
        steps: goToStep(state.steps, payload),
      };
    case NAVIGATION.RETURN_TO_IDLE:
      return {
        ...state,
        steps: returnToIdle(state.steps),
      };
    case NAVIGATION.SELECT_IMAGE_INDEX:
      return {
        ...state,
        steps: selectImageByIndex(state.steps, payload),
      };
  }
  return state;
};
