import React from 'react';
import { hot } from 'react-hot-loader';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { P2 } from '../../styles';
import CheckmarkIcon from '../../assets/images/icons/checkmark.png';

const Container = styled.div`
  position: relative;
  top: -30px;
  width: 100%;
  pointer-events: ${props => (props.visible ? 'auto' : 'none')};
  opacity: ${props => (props.visible ? 1 : 0)};
  transform: ${props => (props.visible ? 'none' : 'translateX(-20px)')};
  transition: ${props => (props.visible ? 'all 0.25s ease-out 0.1s' : 'none')};
  color: #191919;
`;
const Headline = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 40px;
`;

const List = styled.div`
  padding: 10px 0;
`;

const ListItem = styled.div`
  padding: 10px 0;
  padding-left: 45px;
  font-size: 16px;
  line-height: 1.63;
  background-image: url("${CheckmarkIcon}");
  background-repeat: no-repeat;
  background-position: 0 50%;
  background-size: 28px 24px;
`;

class RangeQuestionsResult extends React.Component {
  static propTypes = {
    success: PropTypes.bool,
    rangeResultData: PropTypes.object,
  };

  render() {
    const data = this.props.rangeResultData[this.props.success ? 'success' : 'fail'];

    return (
      <Container visible={true}>
        <Headline>{data.headline}</Headline>

        {data.content.map((content, index) => {
          switch (content.type) {
            case 'paragraph':
              return <P2 key={index}>{content.paragraph}</P2>;
            case 'list':
              return (
                <List key={index}>
                  {content.list.map((item, i) => {
                    return <ListItem key={i}>{item}</ListItem>;
                  })}
                </List>
              );
            default:
              return;
          }
        })}
      </Container>
    );
  }
}

export default hot(module)(RangeQuestionsResult);
