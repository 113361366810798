import ua from 'universal-analytics';

let uaVisitor = null;

function getSerialNumberFromUrlParameters() {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const serialnumber = params.get('serialnumber');
  return serialnumber != null ? serialnumber : 'UnknownDevice';
}

export function uaInitialize() {
  uaVisitor = ua('UA-157279244-1', getSerialNumberFromUrlParameters(), { strictCidFormat: false });
  if (uaVisitor) {
    // Set campaign name
    uaVisitor.set('campaignName', 'Mazda-EV-Experience');
  } else {
    // eslint-disable-next-line no-console
    console.warn('uaVisitor is ' + uaVisitor);
  }
}

export function uaTriggerPageView(docPath, docTitle, sessionControl) {
  if (uaVisitor) {
    uaVisitor.pageview({ dp: docPath, dt: docTitle, sessionControl: sessionControl }).send();
  } else {
    // eslint-disable-next-line no-console
    console.warn('uaVisitor is ' + uaVisitor);
  }
}

export function uaTriggerEvent(eventCategory, eventAction, eventLabel) {
  if (uaVisitor) {
    uaVisitor.event(eventCategory, eventAction, eventLabel).send();
  } else {
    // eslint-disable-next-line no-console
    console.warn('uaVisitor is ' + uaVisitor);
  }
}

export function uaSetLanguage(language) {
  if (uaVisitor) {
    uaVisitor.set('ul', language);
  } else {
    // eslint-disable-next-line no-console
    console.warn('uaVisitor is ' + uaVisitor);
  }
}
