import styled, { css } from 'styled-components';
import { colors, H3 } from '../../styles';

export const CloseButtonContainer = styled.div`
  position: absolute;
  top: -23px;
  right: -21px;
  width: 45px;
  height: 45px;
  z-index: 2;
`;

export const Fineprint = styled.div`
  line-height: 15px;
  font-size: 12px;
  color: #6e6e6e;
  clear: left;
  margin-top: 25px;
`;

export const StyledH3 = styled(H3)`
  margin: 0 0 25px 0;
`;

export const MultiHotSpotTitle = styled(H3)`
  position: absolute;
  top: 0;
  color: white;
`;

const boxEntering = css`
  transform: translate3d(-50%, -15%, 0);
  opacity: 0;
`;

const boxEntered = css`
  transform: translate3d(-50%, -50%, 0);
  opacity: 1;
`;

const boxExiting = css`
  transform: translate3d(-50%, -85%, 0);
  transition-duration: 0.25s;
  opacity: 0;
  transition-timing-function: cubic-bezier(0.49, 0, 0.85, 0.72);
`;

const boxExited = css`
  transform: translate3d(-50%, -85%, 0);
  transition-duration: 0.25s;
  opacity: 0;
  transition-timing-function: cubic-bezier(0.49, 0, 0.85, 0.72);
`;

const modalBaseWidth = 512;

export const Container = styled.div`
  background-color: ${props => (props.clear ? 'none' : 'white')};
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  width: ${props => (props.wide ? modalBaseWidth * 2 : props.mediumWide ? modalBaseWidth * 1.5 : modalBaseWidth)}px;
  transition: transform 0.5s cubic-bezier(0.105, 0.005, 0.22, 0.99), opacity 0.5s ease-out;
  ${props => {
    switch (props.transitionState) {
      case 'entering':
        return boxEntering;
      case 'entered':
        return boxEntered;
      case 'exiting':
        return boxExiting;
      case 'exited':
      default:
        return boxExited;
    }
  }}
`;

const imageEntering = css`
  opacity: 0;
  transform: scale3d(1.1, 1.1, 1);
`;

const imageEntered = css`
  opacity: 1;
  transform: scale3d(1, 1, 1);
`;

const imageExiting = css`
  opacity: 0;
  transform: scale3d(1.1, 1.1, 1);
`;

const imageExited = css`
  opacity: 0;
  transform: scale3d(1.1, 1.1, 1);
`;

export const ImageContainer = styled.div`
  padding-top: ${props => (props.narrow ? 33.3 : props.square ? 100 : 66.6)}%;
  overflow: hidden;
  position: relative;
  background-color: ${colors.darkGrey};
`;

export const WideImageContainer = styled.div`
  padding-top: 54px;
  overflow: hidden;
  position: relative;
  background-color: ${colors.darkGrey};
`;

export const Image = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-image: url(${props => props.image});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  transition: transform 1.2s cubic-bezier(0.105, 0.005, 0.22, 0.99) 0.25s, opacity 0.5s ease-out 0.25s;
  backface-visibility: hidden;
  ${props => {
    switch (props.transitionState) {
      case 'entering':
        return imageEntering;
      case 'entered':
        return imageEntered;
      case 'exiting':
        return imageExiting;
      case 'exited':
      default:
        return imageExited;
    }
  }}
`;

export const TextContainer = styled.div`
  position: relative;
  padding: 56px;
  text-align: left;
`;
