export const DATA_INIT = 'DATA.DATA_INIT';
export const DATA_LOAD_FULFILLED = 'DATA.DATA_LOAD_FULFILLED';
export const DATA_STEPS_LOAD_FULFILLED = 'DATA.DATA_STEPS_LOAD_FULFILLED';
export const DATA_TEXTS_LOAD_FULFILLED = 'DATA.DATA_TEXTS_LOAD_FULFILLED';
export const DATA_LOAD_REJECTED = 'DATA.DATA_LOAD_REJECTED';

export const DATA_BASE_URL = 'https://v2.craft.kiosk.mazda.eu';

export const DATA_UNIT_DISTANCE_KM = 'DATA.DATA_UNIT_DISTANCE_KM';
export const DATA_UNIT_DISTANCE_MILES = 'DATA.DATA_UNIT_DISTANCE_MILES';

export const languages = [
  { handle: 'mmuk', localization: 'UK' },
  { handle: 'mae', localization: 'España' },
  { handle: 'maf', localization: 'France' },
  { handle: 'mag', localization: 'Österreich' },
  { handle: 'bulgaria', localization: 'България' },
  { handle: 'mmb_be_nl', localization: 'België (Nederlands)' },
  { handle: 'mmb_be_fr', localization: 'Belgique (français)' },
  { handle: 'mmb_lu', localization: 'Lëtzebuerg' },
  { handle: 'mmcr', localization: 'Hrvatska' },
  { handle: 'mmcz', localization: 'Česká republika' },
  { handle: 'mmd', localization: 'Deutschland' },
  { handle: 'mmdk', localization: 'Danmark' },
  { handle: 'greece', localization: 'Ελλάδα' },
  { handle: 'mmh', localization: 'Magyarország' },
  { handle: 'mmi', localization: 'Italia' },
  { handle: 'mmirl', localization: 'Éireann' },
  { handle: 'mmn', localization: 'Norge' },
  { handle: 'mmnl', localization: 'Nederland' },
  { handle: 'mmp', localization: 'Portugal' },
  { handle: 'mmpol', localization: 'Polska' },
  { handle: 'mmro', localization: 'România' },
  { handle: 'mms', localization: 'Sverige' },
  { handle: 'mmsi', localization: 'Slovenija' },
  { handle: 'mmsk', localization: 'Slovensko' },
  { handle: 'ms_de', localization: 'Schweiz (deutsch)' },
  { handle: 'ms_fr', localization: 'Suisse (français)' },
  { handle: 'ms_it', localization: 'Svizzera (italiano)' },
];
