import React from 'react';
import { hot } from 'react-hot-loader';
import PropTypes from 'prop-types';
import PlusDot from './PlusDot';
import { Container, CloseButtonContainer, StyledH3 } from './Modal.style';
import styled from 'styled-components';
import VideoWithPoster from './ModalVideoWithPoster';

export const TextBlockContainer = styled.div`
  text-align: left;
  padding: 42px 57px 60px;
`;

const VideoContainer = styled.div`
  padding-top: 56.2%;
  overflow: hidden;
  position: relative;
`;

const Headline = styled(StyledH3)`
  position: absolute;
  padding: 30px 0 0 50px;
  top: 0;
  left: 0;
  color: white;
  font-weight: normal;
`;

class HotSpotModal extends React.Component {
  static propTypes = {
    hotSpot: PropTypes.object,
    transitionState: PropTypes.string,
    close: PropTypes.func,
  };

  render() {
    const { hotSpot, transitionState, close } = this.props;
    const { video, image } = hotSpot;
    return (
      <Container transitionState={transitionState} wide clear>
        <VideoContainer>
          <VideoWithPoster video={video} posterImage={image} onVideoEnded={close} controls dontLoop />
          <Headline>{hotSpot.title}</Headline>
        </VideoContainer>
        <CloseButtonContainer onClick={close}>
          <PlusDot visible={transitionState == 'entered'} type="close" />
        </CloseButtonContainer>
      </Container>
    );
  }
}

export default hot(module)(HotSpotModal);
