import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import GlobalStyle, { colors, screenSize, easings } from 'styles';
import PropTypes from 'prop-types';
import { goToStepIndex, selectStageImageByIndex, returnToIdle, selectLanguage } from '../../actions/navigationActions';
import { getAllSteps, getCurrentStep } from '../../selectors/navigationSelectors';
import { getCombinedDailyRange, getSelectedChargingValues, getChargingCounter } from '../../selectors/interactionSelectors';
import StepNavigation from '../navigation/StepNavigation';
import IdleStep from '../steptypes/IdleStep';
import LoadingStep from '../steptypes/LoadingStep';
import * as NAVIGATION from '../../constants/navigationConstants';
// import RefreshIcon from '../../assets/images/icons/refresh.png';
import mazdaFontRegular from '../../assets/fonts/MazdaType-Regular.otf';
import mazdaFontBold from '../../assets/fonts/MazdaType-Bold.otf';
import arialFontRegular from '../../assets/fonts/Arial-Regular.ttf';
import arialFontBold from '../../assets/fonts/Arial-Bold.ttf';
import SideBar from '../sideBar/SideBar';
import { fetchAll } from '../../actions/dataActions';
import Stage from '../stage/Stage';
import PreLoader from '../misc/PreLoader';
import UniversalAnalytics from '../misc/UniversalAnalytics';

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: ${screenSize.x}px;
  height: ${screenSize.y}px;
  overflow: hidden;
  text-align: center;
  color: ${colors.white};
  background: black;
  @font-face {
    font-family: 'Mazda';
    src: url(${mazdaFontRegular});
    font-weight: normal;
  }
  @font-face {
    font-family: 'Mazda';
    src: url(${mazdaFontBold});
    font-weight: bold;
  }
  @font-face {
    font-family: 'OwnArial';
    src: url(${arialFontRegular});
    font-weight: normal;
  }
  @font-face {
    font-family: 'OwnArial';
    src: url(${arialFontBold});
    font-weight: bold;
  }
  font-family: ${props => (props.useArial ? 'OwnArial' : 'Mazda')};
  font-weight: normal;
  user-select: none;
  a,
  div,
  textarea,
  select,
  input,
  button {
    outline: none;
    &:focus {
      outline: none;
    }
  }
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
`;

// const ReloadTapArea = styled.div`
//   position: absolute;
//   left: 0;
//   top: 0;
//   height: 126px;
//   width: 126px;
//   padding: 23px;
// `;

// const ReloadButton = styled.div`
//   width: 80px;
//   height: 80px;
//   transform: ${props => (props.show ? 'none' : 'translateY(-100%) rotate(180deg)')};
//   opacity: ${props => (props.show ? 1 : 0)};
//   transition: all 0.5s ${props => (props.show ? easings.outStrong : easings.inStrong)};
// `;

class Page extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showNavigation: false, inTransitionToIdle: false, reloadButtonVisible: false, stageVideoHasFinished: false };
    this.fadeInNavigation = this.fadeInNavigation.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.startIdleCountdown = this.startIdleCountdown.bind(this);
    this.transitionToIdle = this.transitionToIdle.bind(this);
    this.switchToIdleStep = this.switchToIdleStep.bind(this);
    this.clearTimeouts = this.clearTimeouts.bind(this);

    this.transitionToIdleTimeout = null;
    this.switchToIdleTimeout = null;
    this.transitionToIdleAfterSeconds = 180;
    this.transitionToIdleDuration = 2;
    this.showReloadButtonTimer = null;
  }

  static propTypes = {
    setWindowSize: PropTypes.func,
    goToStepIndex: PropTypes.func,
    steps: PropTypes.array,
    currentStep: PropTypes.object,
    selectStageImageByIndex: PropTypes.func,
    fetchAll: PropTypes.func,
    rangeCounter: PropTypes.object,
    combinedDailyRange: PropTypes.number,
    chargingCounter: PropTypes.object,
    selectedChargingValues: PropTypes.object,
    returnToIdle: PropTypes.func,
    allLanguages: PropTypes.array,
    currentLanguage: PropTypes.string,
    selectLanguage: PropTypes.func,
  };

  componentDidMount() {
    window.addEventListener('workboxregistered', this.handleWorkboxRegistered.bind(this));
  }

  handleReloadTapAreaClick() {
    if (this.state.reloadButtonVisible) location.reload(false);
    else {
      this.setState({ reloadButtonVisible: true });
      this.showReloadButtonTimer = setTimeout(() => this.setState({ reloadButtonVisible: false }), 3000);
    }
  }

  handleWorkboxRegistered() {
    this.props.fetchAll();
  }

  fadeInNavigation() {
    this.setState({ showNavigation: true });
  }

  transitionToIdle() {
    if (this.props.currentStep.type !== NAVIGATION.STEP_TYPE_IDLE && !this.state.inTransitionToIdle) {
      this.setState({ inTransitionToIdle: true });
      window.clearTimeout(this.switchToIdleTimeout);
      this.switchToIdleTimeout = window.setTimeout(this.switchToIdleStep, this.transitionToIdleDuration * 1000);
    }
  }

  switchToIdleStep() {
    const { currentStep, returnToIdle } = this.props;
    if (currentStep.type !== NAVIGATION.STEP_TYPE_IDLE) {
      returnToIdle();
      this.setState({ showNavigation: false, inTransitionToIdle: false, stageVideoHasFinished: false });
    }
  }

  onKeyDown(e) {
    // Return to idle mode with 'i'
    if (e.keyCode === 73) this.transitionToIdle();
  }

  startIdleCountdown() {
    if (this.props.currentStep.type !== NAVIGATION.STEP_TYPE_IDLE) {
      window.clearTimeout(this.transitionToIdleTimeout);
      this.transitionToIdleTimeout = window.setTimeout(this.transitionToIdle, this.transitionToIdleAfterSeconds * 1000);
    }
  }

  clearTimeouts() {
    window.clearTimeout(this.transitionToIdleTimeout);
    window.clearTimeout(this.switchToIdleTimeout);
  }

  componentDidUpdate(prevProps) {
    const lastStepType = prevProps.currentStep.type;
    const thisStepType = this.props.currentStep.type;
    if (thisStepType !== lastStepType) {
      if (thisStepType !== NAVIGATION.STEP_TYPE_IDLE) {
        this.startIdleCountdown();
      } else {
        this.clearTimeouts();
      }
    }
  }

  componentWillUnmount() {
    this.clearTimeouts();
    window.removeEventListener('workboxregistered', this.handleWorkboxRegistered.bind(this));
  }

  render() {
    const {
      steps,
      currentStep,
      goToStepIndex,
      selectStageImageByIndex,
      rangeCounter,
      combinedDailyRange,
      chargingCounter,
      selectedChargingValues,
      allLanguages,
      currentLanguage,
      selectLanguage,
    } = this.props;
    const { inTransitionToIdle } = this.state;
    const playStageIntroVideo = currentStep.stage && currentStep.stage.introVideo && !this.state.stageVideoHasFinished && !this.state.inTransitionToIdle;
    return (
      <Container tabIndex="0" onKeyDown={this.onKeyDown} onClick={this.startIdleCountdown} useArial={currentLanguage == 'greece'}>
        <GlobalStyle />
        <UniversalAnalytics currentStep={currentStep} currentLanguage={currentLanguage} />
        <PreLoader steps={steps} />
        {currentStep.type === NAVIGATION.STEP_TYPE_LOADING && <LoadingStep />}
        {currentStep.type === NAVIGATION.STEP_TYPE_IDLE && (
          <IdleStep
            selectLanguage={selectLanguage}
            allLanguages={allLanguages}
            currentLanguage={currentLanguage}
            goToStepIndex={goToStepIndex}
            startOutTransition={this.fadeInNavigation}
            stageContent={currentStep.stage}
          />
        )}
        {currentStep.type !== NAVIGATION.STEP_TYPE_IDLE && currentStep.type !== NAVIGATION.STEP_TYPE_LOADING && (
          <Stage
            step={currentStep}
            fadeOut={inTransitionToIdle}
            rangeCounter={rangeCounter}
            rangeCounterValue={combinedDailyRange}
            chargingCounter={chargingCounter}
            selectedChargingValues={selectedChargingValues}
            playVideo={playStageIntroVideo}
            onClickReplay={() => this.setState({ stageVideoHasFinished: false })}
            onStageVideoFinished={() => this.setState({ stageVideoHasFinished: true })}
          />
        )}
        <SideBar
          step={currentStep}
          steps={steps}
          selectStageImageByIndex={selectStageImageByIndex}
          hide={!currentStep.showSideBar || inTransitionToIdle}
          returnToIdleStep={this.transitionToIdle}
          currentLanguage={currentLanguage}
          buttonsVisible={!playStageIntroVideo}
        />
        <StepNavigation
          steps={steps}
          goToStepIndex={goToStepIndex}
          returnToHome={this.transitionToIdle}
          show={currentStep.showNavigation || this.state.showNavigation}
        />
        {/* <ReloadTapArea onClick={this.handleReloadTapAreaClick.bind(this)}>
          <ReloadButton show={this.state.reloadButtonVisible}>
            <img src={RefreshIcon} />
          </ReloadButton>
        </ReloadTapArea> */}
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  steps: getAllSteps(state),
  currentStep: getCurrentStep(state),
  rangeCounter: state.interaction.rangeCalculation.counter,
  combinedDailyRange: getCombinedDailyRange(state),
  chargingCounter: getChargingCounter(state),
  selectedChargingValues: getSelectedChargingValues(state),
  allLanguages: state.navigation.languages,
  currentLanguage: state.navigation.currentLanguage,
});

export default connect(
  mapStateToProps,
  { goToStepIndex, selectStageImageByIndex, fetchAll, returnToIdle, selectLanguage }
)(Page);
