import * as NAVIGATION from '../../constants/navigationConstants';
import React, { createRef } from 'react';
import { hot } from 'react-hot-loader';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors, screenSize, infoSideBarWidth, navigationBarHeight, H1, P2, StandardButton } from 'styles';
import HotSpotContainer from './HotSpotContainer';
import StageImageBox from './StageImageBox';
import Curtain from '../misc/Curtain';
import QuestionsIcon from '../../assets/images/icons/question.png';
import QRModalContainer from './QRModalContainer';
import StageVideo from './StageVideo';
import InviteStepFallbackImage from 'assets/images/steps/stay_in_loop_darker.jpg';

const Container = styled.div`
  float: left;
  width: ${screenSize.x - infoSideBarWidth}px;
  height: ${screenSize.y - navigationBarHeight}px;
  background: ${colors.darkGrey};
  position: relative;
  transform: ${props => (props.fadeOut ? 'scale(1.2)' : 'none')};
  transition: transform ${props => (props.fadeOut ? '1.5s cubic-bezier(0.51, 0.04, 0.93, 0.53)' : '1.5s ease-out')};
  overflow: hidden;
`;

const InviteContainer = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: absolute;
  text-align: left;
  padding: 80px 0 0 80px;
  color: white;
`;

const InviteInfos = styled.div`
  opacity: ${props => (props.show ? 1 : 0)};
  transition: opacity 0.3s ease-out;
`;

const InviteH = styled(H1)`
  margin-bottom: ${props => (props.compressed ? '50px' : '120px')};
`;

const InviteP = styled(P2)`
  max-width: 480px;
  margin: 0 0 35px 0;
`;

const InviteSmallHeadline = styled(InviteP)`
  font-weight: bold;
  margin-bottom: 0px;
`;

const QRButton = styled(StandardButton)`
  margin-top: 10px;
  margin-right: 50px;
  float: left;
`;

const FurtherQuestions = styled.div`
  display: ${props => (props.compressed ? 'inline-block' : 'block')};
  margin-top: 8px;
  position: ${props => (props.compressed ? 'relative' : 'absolute')};
  bottom: ${props => (props.compressed ? '0' : '20px')};
`;

const FurtherQuestionsIcon = styled.img`
  float: left;
  width: 50px;
  height: 50px;
  margin-top: 3px;
  margin-right: 25px;
`;

const StageFineprint = styled.div`
  max-width: 1150px;
  float: left;
  font-size: 10px;
  line-height: 20px;
`;

const SubP = styled.span`
  display: block;
`;

const FurtherQuestionsText = styled.div`
  float: left;
`;

class Stage extends React.Component {
  constructor(props) {
    super(props);
    this.renderInviteContent = this.renderInviteContent.bind(this);
    this.openQRModal = this.openQRModal.bind(this);
    this.closeQRModal = this.closeQRModal.bind(this);
    this.state = { fadeIn: true, showQRModal: false };
    this.inviteInfosRef = createRef();
  }

  static propTypes = {
    step: PropTypes.object,
    children: PropTypes.array,
    fadeOut: PropTypes.bool,
    playVideo: PropTypes.bool,
    onClickReplay: PropTypes.func.isRequired,
    onStageVideoFinished: PropTypes.func.isRequired,
  };

  renderHotSpotContainer(hotSpots, imageIndex, fadeOut, stepTitle) {
    if (hotSpots) {
      return <HotSpotContainer key={'hotSpotContainer' + stepTitle} hotSpots={hotSpots} stageImageIndex={imageIndex} fadeOut={fadeOut} />;
    }
  }

  openQRModal() {
    this.setState({ showQRModal: true });
  }

  closeQRModal() {
    this.setState({ showQRModal: false });
  }

  renderFurtherQuestionsModule(service, compressed) {
    return (
      <FurtherQuestions compressed={compressed}>
        <FurtherQuestionsIcon src={QuestionsIcon} />
        <FurtherQuestionsText>
          <InviteSmallHeadline>{service.furtherQuestions.headline}</InviteSmallHeadline>
          <InviteP>{service.furtherQuestions.copy}</InviteP>
        </FurtherQuestionsText>
      </FurtherQuestions>
    );
  }

  renderComponenthWithLinebreaks(Component, content, index) {
    return <Component key={index}>{content != null && content.split('\n').map((item, i) => <SubP key={i}>{item}</SubP>)}</Component>;
  }

  renderInviteContent(step) {
    const { service } = step;
    const hasFineprint = service.serviceStageFineprint != '' && service.serviceStageFineprint != null;
    const bigContent = this.inviteInfosRef.current && this.inviteInfosRef.current.offsetHeight >= 580;
    const { qrStage } = service;
    const showFurtherQuestions = service.furtherQuestions.copy != null || service.furtherQuestions.headline != null;
    const qrCodeAvailable = qrStage.url !== null && qrStage.url !== '';

    return (
      <InviteContainer>
        <InviteInfos show={!this.state.showQRModal} ref={this.inviteInfosRef}>
          <InviteH compressed={hasFineprint}>{service.headline}</InviteH>
          {service.text.map((textBlock, index) => {
            if (textBlock.type == 'paragraph') {
              return this.renderComponenthWithLinebreaks(InviteP, textBlock.content, index);
            } else {
              return <InviteSmallHeadline key={index}>{textBlock.content}</InviteSmallHeadline>;
            }
          })}
          {qrCodeAvailable && (
            <QRButton inverted={true} onClick={this.openQRModal}>
              {service.qrStage.callToAction}
            </QRButton>
          )}
          {showFurtherQuestions && this.renderFurtherQuestionsModule(service, hasFineprint || bigContent)}
        </InviteInfos>
        {qrCodeAvailable && (
          <QRModalContainer showModal={this.state.showQRModal} close={this.closeQRModal} qrLink={qrStage.url} headline={qrStage.headline} copy={qrStage.copy} />
        )}
        {/* {hasFineprint && <StageFineprint dangerouslySetInnerHTML={{ __html: service.serviceStageFineprint }} />} */}
        {hasFineprint && this.renderComponenthWithLinebreaks(StageFineprint, service.serviceStageFineprint, 0)}
      </InviteContainer>
    );
  }

  componentDidMount() {
    window.setTimeout(() => {
      this.setState({ fadeIn: false });
    }, 0);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.step.id !== this.props.step.id) this.closeQRModal();
  }

  render() {
    const { step, fadeOut } = this.props;
    const { info, stage } = step;
    const { fadeIn } = this.state;
    const imageIndex = stage.images.length < 2 ? 0 : stage.images.findIndex(img => img.selected);
    const fallbackImage = step.type === NAVIGATION.STEP_TYPE_INVITE ? InviteStepFallbackImage : null;
    return (
      <Container fadeOut={fadeOut || this.state.fadeIn}>
        {stage.introVideo && (
          <StageVideo
            playVideo={this.props.playVideo}
            src={stage.introVideo}
            fallbackImages={stage.images}
            onClickReplay={this.props.onClickReplay}
            onEnded={this.props.onStageVideoFinished}
          />
        )}
        {(!stage.introVideo || !this.props.playVideo) && <StageImageBox key={step.title} images={stage.images} fallbackImage={fallbackImage} />}
        {step.type === NAVIGATION.STEP_TYPE_INVITE && this.renderInviteContent(step)}
        {info && (!stage.introVideo || !this.props.playVideo) && this.renderHotSpotContainer(info.hotSpots, imageIndex, fadeOut, step.title)}
        <Curtain down={fadeOut || fadeIn} transitionDuration={1000} transitionDelay={400} scaleFactor={1.1} />
      </Container>
    );
  }
}

export default hot(module)(Stage);
