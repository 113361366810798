import * as NAVIGATION from '../constants/navigationConstants';
import { saveValue } from '../services/LocalStorageService';

export function goToStepIndex(stepIndex) {
  return dispatch =>
    dispatch({
      type: NAVIGATION.GO_TO_INDEX,
      payload: stepIndex,
    });
}

export function returnToIdle() {
  return dispatch =>
    dispatch({
      type: NAVIGATION.RETURN_TO_IDLE,
    });
}

export function selectStageImageByIndex(imageIndex) {
  return dispatch =>
    dispatch({
      type: NAVIGATION.SELECT_IMAGE_INDEX,
      payload: imageIndex,
    });
}

export function selectLanguage(languageHandle) {
  saveValue('language', languageHandle);
  return dispatch =>
    dispatch({
      type: NAVIGATION.SELECT_LANGUAGE,
      payload: languageHandle,
    });
}
