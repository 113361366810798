import React from 'react';
import { hot } from 'react-hot-loader';
import PropTypes from 'prop-types';
import PlusDot from './PlusDot';
import styled from 'styled-components';
import { H3 } from '../../styles';
import { Container, ImageContainer, Image, CloseButtonContainer } from './Modal.style';
import HotSpotModalTextBlock from './HotSpotModalTextBlock';
import VideoWithPoster from './ModalVideoWithPoster';

export const MultiHotSpotTitle = styled(H3)`
  width: 100%;
  position: absolute;
  top: ${props => (props.collapse ? '0' : '42%')};
  color: white;
  transition: all 800ms cubic-bezier(0.45, 0.01, 0.39, 1);
`;

export const ChangeSlideButton = styled.div`
  position: absolute;
  top: 56px;
  width: 51px;
  height: 58px;
  border-radius: 3px;
  left: ${props => (props.right ? 'auto' : '-10px')};
  right: ${props => (props.right ? '-10px' : 'auto')};
  background: black;
  color: white;
  font-weight: bold;
  padding: 6px 13px;
  box-sizing: border-box;
  cursor: pointer;
`;

export const Curtain = styled.div`
  left: 0;
  top: 0;
  width: 100%;
  height: ${props => (props.collapse ? '64px' : '100%')};
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  transition: all 800ms cubic-bezier(0.45, 0.01, 0.39, 1);
  transform: scale3d(1.01, 1.01, 1.1);
`;

export const BottomContainer = styled.div`
  position: relative;
  text-align: left;
  height: ${props => props.height}px;
`;

export const TextBlockContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  padding: 42px 82px 50px;
  visibility: ${props => (props.visible ? 'visible' : 'hidden')};
  opacity: ${props => (props.visible ? 1 : 0)};
  transform: ${props => (props.visible ? 'none' : 'translateY(15px)')};
  transition: opacity 0.4s ease-out, transform 0.4s ease-out;
`;

export const ArrowContainer = styled.div`
  transform: ${props => (props.mirror ? 'scaleX(-1)' : 'none')};
`;

class HotSpotMultiModal extends React.Component {
  static propTypes = {
    hotSpot: PropTypes.object,
    transitionState: PropTypes.string,
    close: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = { showSlideId: 0 };
    this.previousSlide = this.previousSlide.bind(this);
    this.nextSlide = this.nextSlide.bind(this);
    this.textBlockRefs = [];
    for (let r = 0; r < props.hotSpot.slides.length; r++) {
      this.textBlockRefs.push(React.createRef());
    }
  }

  arrowSvg() {
    return (
      <svg width="24" height="40">
        <path d="M15 14 L7 22 L15 30" stroke="white" strokeWidth="3" fill="none" />
      </svg>
    );
  }

  previousSlide() {
    this.setState({ showSlideId: this.state.showSlideId - 1 });
  }

  nextSlide() {
    this.setState({ showSlideId: this.state.showSlideId + 1 });
  }

  renderSlideTextBlock(slide, index, visible) {
    return (
      <TextBlockContainer key={index} visible={visible} ref={this.textBlockRefs[index]}>
        <HotSpotModalTextBlock headline={slide.headline} copy={slide.copy} list={slide.list} fineprint={slide.finePrint} />
      </TextBlockContainer>
    );
  }

  renderSlideTextBlocks(slides, showSlideIndex) {
    return slides.map((slide, index) => this.renderSlideTextBlock(slide, index, index === showSlideIndex));
  }

  render() {
    const { showSlideId } = this.state;
    const { hotSpot, transitionState, close } = this.props;
    const { slides } = hotSpot;
    const slideAmount = slides.length;
    const image = slides[showSlideId].image;
    const video = slides[showSlideId].video;
    const textBoxHeights = this.textBlockRefs.map(r => (r.current ? r.current.clientHeight : 0));
    const bottomBoxHeight = textBoxHeights.sort((a, b) => b - a)[0];

    return (
      <Container transitionState={transitionState}>
        <ImageContainer>
          {!video && <Image transitionState={transitionState} image={image} />}
          {video && <VideoWithPoster video={video} posterImage={image} key={video} />}
          <Curtain collapse={showSlideId > 0} />
          <MultiHotSpotTitle collapse={showSlideId > 0}>{hotSpot.title}</MultiHotSpotTitle>
        </ImageContainer>
        <BottomContainer height={bottomBoxHeight}>
          {this.renderSlideTextBlocks(slides, showSlideId)}
          {showSlideId > 0 && (
            <ChangeSlideButton onClick={this.previousSlide}>
              <ArrowContainer>{this.arrowSvg()}</ArrowContainer>
            </ChangeSlideButton>
          )}
          {showSlideId < slideAmount - 1 && (
            <ChangeSlideButton right={true} onClick={this.nextSlide}>
              <ArrowContainer mirror={true}>
                <ArrowContainer>{this.arrowSvg()}</ArrowContainer>
              </ArrowContainer>
            </ChangeSlideButton>
          )}
        </BottomContainer>
        <CloseButtonContainer onClick={close}>
          <PlusDot visible={transitionState == 'entered'} type="close" />
        </CloseButtonContainer>
      </Container>
    );
  }
}

export default hot(module)(HotSpotMultiModal);
