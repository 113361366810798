import styled, { createGlobalStyle } from 'styled-components';
import styledNormalize from 'styled-normalize';
import { css } from 'styled-components';

export const colors = {
  lightGrey: '#f6f6f6',
  mediumLightGrey: '#dddddd',
  mediumGrey: '#6e6e6e',
  darkGrey: '#191919',
  darkRed: '#910a2d',
};

export const screenSize = {
  x: 1920,
  y: 1080,
};

export const easings = {
  inStrong: 'cubic-bezier(.66,.02,.98,.63)',
  outStrong: 'cubic-bezier(.11,.36,.34,.99)',
};

export const navigationBarHeight = 150;
export const infoSideBarWidth = 630;

const headlineStyle = css`
  font-weight: bold;
  letter-spacing: 3px;
  text-transform: uppercase;
`;

export const H1 = styled.h1`
  ${headlineStyle};
  font-size: 46px;
  line-height: 54px;
`;

export const H2 = styled.h2`
  ${headlineStyle};
  letter-spacing: 1.5px;
  font-size: 24px;
`;

export const H3 = styled.h3`
  ${headlineStyle};
  letter-spacing: 2px;
  font-size: 20px;
`;

export const H4 = styled.h4`
  ${headlineStyle};
  letter-spacing: 1px;
  font-size: 16px;
`;

export const P1 = styled.p`
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0;
`;

export const P2 = styled.p`
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0;
`;

export const Footnote = styled.p`
  font-weight: normal;
  font-size: 10px;
  line-height: 20px;
  margin: 0;
  letter-spacing: 0;
  color: #6e6e6e;
`;

export const P2bold = styled(P2)`
  font-weight: bold;
  margin: 30px 0;
`;

export const Label = styled.div`
  font-weight: bold;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
`;

export const inputStyle = css`
  width: 100%;
  max-width: 100%;
  display: block;
  border: 1px solid ${colors.darkGrey};
  border-radius: 4px;
  padding: 12px 20px;
  margin: 12px 0;
  box-sizing: border-box;
  height: 55px;
  outline: none;
  box-shadow: none;
  :focus {
    outline-color: transparent;
    outline-style: none;
    border-color: ${colors.darkRed};
  }
`;

export const StandardButton = styled.div`
  display: inline-block;
  border-radius: 4px;
  font-size: 16px;
  font-weight: bold;
  padding: 18px 48px;
  letter-spacing: 1.6px;
  text-transform: uppercase;
  color: ${props => (props.inverted ? 'black' : 'white')};
  background-color: ${props => (props.inverted ? 'white' : props.inactive ? '#bbb' : 'black')};
  cursor: ${props => (props.inactive ? 'auto' : 'pointer')};
`;

const GlobalStyle = createGlobalStyle`
  ${styledNormalize}
`;

export default GlobalStyle;
