import { createSelector } from 'reselect';

const getInteraction = state => state.interaction;

export const getSelectedChargingSituation = createSelector(
  [getInteraction],
  interaction => {
    const { chargingCalculation } = interaction;
    return chargingCalculation.situations.find(s => s.selected);
  }
);

export const getSelectedChargingSituationOptions = createSelector(
  [getSelectedChargingSituation],
  selectedSituation => {
    if (!selectedSituation) return null;
    return selectedSituation.options.find(s => s.selected);
  }
);

export const getOpenChargingModal = createSelector(
  [getInteraction, getSelectedChargingSituation],
  (interaction, selectedSituation) => {
    if (selectedSituation === null) return null;
    return interaction.chargingCalculation.infoModalOpen ? selectedSituation.infoModal : null;
  }
);

export const getSelectedChargingValues = createSelector(
  [getSelectedChargingSituationOptions],
  selectedSituationOptions => {
    if (!selectedSituationOptions) return null;
    return selectedSituationOptions.chargingValues;
  }
);

export const getChargingCounter = createSelector(
  [getInteraction],
  interaction => {
    return interaction.chargingCalculation.counter;
  }
);

export const getCombinedDailyRange = createSelector(
  [getInteraction],
  interaction => {
    const { rangeCalculation } = interaction;
    let combinedValue = 0;
    rangeCalculation.dailyStations.forEach(station => (combinedValue += station.currentValue));
    return combinedValue;
  }
);
