import React from 'react';
import { hot } from 'react-hot-loader';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import PlusDotSmall from './PlusDotSmall';
import { uaTriggerEvent } from '../../services/TrackingService';

const HotSpotButton = styled.div`
  color: white;
  padding: 16px 36px 16px 55px;
  text-transform: uppercase;
  display: inline-block;
  position: absolute;
  left: ${props => props.position.x}%;
  top: ${props => props.position.y}%;
  pointer-events: ${props => (props.visible ? 'all' : 'none')};
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
`;

const Background = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: ${props => (props.visible ? '100%' : '50px')};
  height: 100%;
`;

const Title = styled.span`
  display: inline-block;
  font-weight: bold;
  transition: opacity 0.4s, transform 0.6s ease-out;
  opacity: ${props => (props.visible ? 1 : 0)};
  transform: ${props => (props.visible ? 'none' : 'translateX(-10px)')};
  position: relative;
  z-index: 1;
  backface-visibility: hidden;

  text-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 1.6px;
`;

class HotSpot extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.initialize = this.initialize.bind(this);
    this.state = { initialized: false };
    this.initializationTimeout = null;
  }

  static propTypes = {
    title: PropTypes.string,
    position: PropTypes.object,
    handleSelect: PropTypes.func,
    index: PropTypes.number,
    active: PropTypes.bool,
    visible: PropTypes.bool,
    startDelay: PropTypes.number,
    isVideoHotspot: PropTypes.bool,
  };

  handleClick() {
    const { index, handleSelect } = this.props;
    handleSelect(index);
    uaTriggerEvent('Modal Event', 'Modal Opened', this.props.title);
  }

  componentDidMount() {
    this.initializationTimeout = window.setTimeout(this.initialize, this.props.startDelay);
  }

  componentWillUnmount() {
    window.clearTimeout(this.initializationTimeout);
  }

  initialize() {
    this.setState({ initialized: true });
  }

  render() {
    const { title, position, isVideoHotspot } = this.props;
    const visible = this.state.initialized && this.props.visible;
    return (
      <HotSpotButton visible={visible} position={position} onClick={this.handleClick.bind(this)}>
        <Background visible={visible} />
        <PlusDotSmall visible={visible} isVideoHotspot={isVideoHotspot} />
        <Title visible={visible}>{title}</Title>
      </HotSpotButton>
    );
  }
}

HotSpot.defaultProps = {
  startDelay: 100,
};

export default hot(module)(HotSpot);
