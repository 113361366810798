import React from 'react';
import { hot } from 'react-hot-loader';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import austria from '../../assets/images/maps/austria.png';
import benelux from '../../assets/images/maps/belux.png';
import bulgaria from '../../assets/images/maps/bulgaria.png';
import croatia from '../../assets/images/maps/croatia.png';
import czech_republic from '../../assets/images/maps/czech_republic.png';
import denmark from '../../assets/images/maps/denmark.png';
import france from '../../assets/images/maps/france.png';
import germany from '../../assets/images/maps/germany.png';
import greece from '../../assets/images/maps/greece.png';
import hungary from '../../assets/images/maps/hungary.png';
import ireland from '../../assets/images/maps/ireland.png';
import italy from '../../assets/images/maps/italy.png';
//import MCSEE from '../../assets/images/maps/MCSEE.png';
import MS from '../../assets/images/maps/MS.png';
import netherlands from '../../assets/images/maps/netherlands.png';
import norway from '../../assets/images/maps/norway.png';
import poland from '../../assets/images/maps/poland.png';
import portugal from '../../assets/images/maps/portugal.png';
import romania from '../../assets/images/maps/romania.png';
import slovakia from '../../assets/images/maps/slovakia.png';
import slovenia from '../../assets/images/maps/slovenia.png';
import spain from '../../assets/images/maps/spain.png';
import sweden from '../../assets/images/maps/sweden.png';
import uk from '../../assets/images/maps/UK.png';

const languageImages = [
  { handle: 'mmuk', img: uk },
  { handle: 'mae', img: spain },
  { handle: 'maf', img: france },
  { handle: 'mag', img: austria },
  { handle: 'bulgaria', img: bulgaria },
  { handle: 'mmb_be_nl', img: benelux },
  { handle: 'mmb_be_fr', img: benelux },
  { handle: 'mmb_lu', img: benelux },
  { handle: 'mmcr', img: croatia },
  { handle: 'mmcz', img: czech_republic },
  { handle: 'mmd', img: germany },
  { handle: 'mmdk', img: denmark },
  { handle: 'greece', img: greece },
  { handle: 'mmh', img: hungary },
  { handle: 'mmi', img: italy },
  { handle: 'mmirl', img: ireland },
  { handle: 'mmn', img: norway },
  { handle: 'mmnl', img: netherlands },
  { handle: 'mmp', img: portugal },
  { handle: 'mmpol', img: poland },
  { handle: 'mmro', img: romania },
  { handle: 'mms', img: sweden },
  { handle: 'mmsi', img: slovenia },
  { handle: 'mmsk', img: slovakia },
  { handle: 'ms_de', img: MS },
  { handle: 'ms_fr', img: MS },
  { handle: 'ms_it', img: MS },
];

const Image = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center;
`;

class MapImage extends React.Component {
  static propTypes = {
    languageCode: PropTypes.string,
    children: PropTypes.object,
  };

  constructor(props) {
    super(props);
  }

  languageCodeToImg(languageCode) {
    const language = languageImages.find(l => l.handle == languageCode);
    if (language) {
      return language.img;
    } else {
      // eslint-disable-next-line no-console
      console.warn('Language ' + languageCode + ' not found!');
      return null;
    }
  }

  render() {
    const { languageCode, children } = this.props;
    const img = this.languageCodeToImg(languageCode);
    return <Image image={img}>{children}</Image>;
  }
}

export default hot(module)(MapImage);
