import React from 'react';
import { hot } from 'react-hot-loader';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import StepNavigationDot from './StepNavigationDot';
import { colors, navigationBarHeight } from '../../styles';
import HomeButton from './HomeButton';

const Container = styled.div`
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: ${navigationBarHeight}px;
  bottom: 0;
  background-color: ${colors.darkGrey};
  text-align: center;
  transform: ${props => (props.show ? 'none' : 'translateY(100%)')};
  transition: transform 1s cubic-bezier(0.15, 0.63, 0.33, 1) ${props => (props.show ? '0.6s' : '')};
  user-select: none;
`;

const lineStyle = css`
  position: absolute;
  top: 50%;
  left: 50%;
  height: 1px;
  margin-top: 10px;
  width: ${props => props.stepAmount * 200}px;
  backface-visibility: hidden;
  perspective: 1000;
`;

const BackgroundLine = styled.div`
  ${lineStyle}
  transform: translate3d(-50%, -1px, 0);
  background-color: ${colors.mediumGrey};
`;

const BackgroundLineHighlight = styled.div`
  ${lineStyle}
  transform-origin: 0% 50%;
  transform: translate3d(-50%, -1px, 0) scaleX(${props => props.activeStepIndex / props.stepAmount});
  background-color: white;
  transition: transform 0.9s cubic-bezier(0.17, 0.67, 0.45, 1);
`;

class StepNavigation extends React.Component {
  constructor(props) {
    super(props);
    this.renderNavigationDots = this.renderNavigationDots.bind(this);
  }

  static propTypes = {
    steps: PropTypes.array,
    goToStepIndex: PropTypes.func,
    returnToHome: PropTypes.func,
    show: PropTypes.bool,
  };

  renderNavigationDots(steps) {
    return steps.map((step, index) => {
      if (!step.showNavigation) return;
      return (
        <StepNavigationDot
          key={index}
          index={index}
          highlight={step.visited}
          active={step.active}
          goToStepIndex={this.props.goToStepIndex}
          caption={step.title}
        />
      );
    });
  }

  render() {
    const { steps, show, returnToHome } = this.props;

    const activeStepIndex = steps.findIndex(step => step.active);
    const visibleDotAmount = steps.filter(step => step.showNavigation).length - 1;
    const activeVisibleStepIndex = steps.filter((step, index) => {
      return step.showNavigation && index < activeStepIndex;
    }).length;

    return (
      <Container show={show}>
        <HomeButton returnToHome={returnToHome} />
        <BackgroundLine stepAmount={visibleDotAmount} />
        <BackgroundLineHighlight stepAmount={visibleDotAmount} activeStepIndex={activeVisibleStepIndex} />
        {this.renderNavigationDots(steps)}
      </Container>
    );
  }
}

export default hot(module)(StepNavigation);
