import React from 'react';
import { hot } from 'react-hot-loader';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import { colors, Label, easings } from '../../styles';

const fadeIn = keyframes`
  from {opacity: 0; transform: translateX(-15px);}
  to {opacity: 1; transform: none;}
`;

const Container = styled.div`
  position: relative;
  float: left;
  width: 135px;
  box-sizing: border-box;
  margin: 25px 10px 0;
  text-align: center;
  opacity: 0;
  animation: ${fadeIn} ${easings.outStrong} 1.2s;
  animation-fill-mode: forwards;
  animation-delay: ${props => props.index * 100}ms;
`;

const Bubble = styled.div`
  border-radius: 50%;
  overflow: hidden;
  width: 82px;
  height: 82px;
  border-style: solid;
  border-width: 3px;
  border-color: ${props => (props.selected ? colors.darkRed : 'white')};
  margin-left: 24px;
`;

const Caption = styled(Label)`
  margin-top: 20px;
  font-size: 12px;
  line-height: 1.5;
  letter-spacing: 1.6px;
  color: #191919;
`;

class SideBarButton extends React.Component {
  static propTypes = {
    selected: PropTypes.bool,
    image: PropTypes.string,
    caption: PropTypes.string,
    onClick: PropTypes.func,
    index: PropTypes.number,
  };

  render() {
    const { selected, image, caption, index } = this.props;

    return (
      <Container onClick={this.props.onClick} index={index}>
        <Bubble selected={selected}>
          <img src={image} />
        </Bubble>
        <Caption>{caption}</Caption>
      </Container>
    );
  }
}

export default hot(module)(SideBarButton);
