import React from 'react';
import { hot } from 'react-hot-loader';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { P2, P2bold, Footnote } from '../../styles';

const Container = styled.div``;

class TextContent extends React.Component {
  static propTypes = {
    text: PropTypes.array,
  };

  renderTextArea(text) {
    if (text === undefined) return;
    return text.map((textBlock, index) => {
      switch (textBlock.type) {
        case 'headline':
          return <P2bold key={index}>{textBlock.content}</P2bold>;
        case 'paragraph':
          return <P2 key={index}>{textBlock.content}</P2>;
        case 'footnote':
          return <Footnote key={index}>{textBlock.content}</Footnote>;
        default:
          return;
      }
    });
  }

  render() {
    const { text } = this.props;
    return <Container>{this.renderTextArea(text)}</Container>;
  }
}

export default hot(module)(TextContent);
