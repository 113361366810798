import axios from 'axios';
import * as DATA from '../constants/dataConstants';

const BASE_URL = DATA.DATA_BASE_URL + '/';

function getData(service) {
  return axios.get(BASE_URL + service, { responseType: 'json' }).then(response => {
    return response;
  });
}

export async function getSteps() {
  return await Promise.all(
    DATA.languages.map(l => {
      return getData(`steps.json?lang=${l.handle}`);
    })
  )
    .then(allResponses => {
      const allSteps = allResponses
        .filter(response => typeof response.data.meta != 'undefined')
        .map(response => {
          return { language: response.data.meta.language, steps: response.data.steps };
        });
      return allSteps;
    })
    .catch(error => {
      // eslint-disable-next-line no-console
      console.error(error.message);
    });
}

export async function getTexts() {
  return await Promise.all(
    DATA.languages.map(l => {
      return getData(`texts.json?lang=${l.handle}`);
    })
  )
    .then(allResponses => {
      const allTexts = allResponses
        .filter(response => typeof response.data.meta != 'undefined')
        .map(response => {
          return { language: response.data.meta.language, texts: response.data.texts };
        });
      return allTexts;
    })
    .catch(error => {
      // eslint-disable-next-line no-console
      console.error(error.message);
    });
}
