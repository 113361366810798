import React from 'react';
import { hot } from 'react-hot-loader';
import PropTypes from 'prop-types';
import PlusDot from './PlusDot';
import styled from 'styled-components';
import { Container, CloseButtonContainer } from './Modal.style';
import { StandardButton } from '../../styles';
import MapImage from './MapImage';
import QRLink from '../misc/QRLink';

const TextContainer = styled.div`
  padding: 25px 95px;
`;

const MiddleContainer = styled.div`
  width: 100%;
  height: 343px;
  background: #e7e7e7;
`;

const CopyLine = styled.div`
  width: 100%;
  text-align: center;
  font-size: 16px;
  color: #6e6e6e;
  margin: 15px 0;
  line-height: 28px;
`;

const CopyLineBold = styled(CopyLine)`
  color: black;
  font-weight: bold;
`;

const Number = styled(CopyLine)`
  font-size: 46px;
  font-weight: bold;
  color: black;
`;

const Button = styled(StandardButton)`
  margin-top: 140px;
`;

const QrContainer = styled.div`
  display: inline-block;
  background: white;
  padding: 15px;
  margin-top: 55px;
`;

class HotSpotMapModal extends React.Component {
  static propTypes = {
    hotSpot: PropTypes.object,
    transitionState: PropTypes.string,
    close: PropTypes.func,
    currentLanguage: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = { showQrCode: false };
  }

  formatNumber(num) {
    if (num < 1000) return num;
    num = '' + num;
    const position = num.length - 3;
    return [num.slice(0, position), '.', num.slice(position)].join('');
  }

  render() {
    const { hotSpot, transitionState, close, currentLanguage } = this.props;
    const { showQrCode } = this.state;
    const { top, bottom } = hotSpot;
    const qrCodeAvailable = hotSpot.qrCode.url !== null && hotSpot.qrCode.url !== '';
    return (
      <Container transitionState={transitionState}>
        <TextContainer>
          <CopyLine>{top.headlinePart1}</CopyLine>
          <Number>{this.formatNumber(top.headlineChargingStationCount)}</Number>
          <CopyLine>{top.headlinePart2}</CopyLine>
        </TextContainer>
        <MiddleContainer>
          {!showQrCode && (
            <MapImage languageCode={currentLanguage}>
              {qrCodeAvailable ? (
                <Button
                  onClick={() => {
                    this.setState({ showQrCode: true });
                  }}
                >
                  {hotSpot.qrCode.callToAction}
                </Button>
              ) : null}
            </MapImage>
          )}
          {showQrCode && (
            <QrContainer>
              <QRLink url={hotSpot.qrCode.url} size={200} />
            </QrContainer>
          )}
        </MiddleContainer>
        <TextContainer>
          <CopyLineBold>{bottom.headline}</CopyLineBold>
          <CopyLine>{bottom.copy}</CopyLine>
        </TextContainer>
        <CloseButtonContainer onClick={close}>
          <PlusDot visible={transitionState == 'entered'} type="close" />
        </CloseButtonContainer>
      </Container>
    );
  }
}

export default hot(module)(HotSpotMapModal);
