import React from 'react';
import { hot } from 'react-hot-loader';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { LOAD_IMAGE_TIMEOUT } from 'constants/loadingConstants';

const ImageContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: ${props => (props.loaded ? 1 : 0)};
  transition: opacity 400ms linear;
`;

const Image = styled.img`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: ${props => (props.visible === undefined || props.visible ? 1 : 0)};
  transition: opacity 400ms linear;
`;

class StageImage extends React.Component {
  static propTypes = {
    image: PropTypes.string,
    fallbackImage: PropTypes.string,
    onLoad: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = { loaded: false, externalImageLoaded: false };
    this.onLoad = this.onLoad.bind(this);
    this.onLoadTimeout = null;
  }

  onLoad() {
    this.onLoadTimeout = window.setTimeout(() => {
      if (!this.state.loaded) {
        this.setState({ loaded: true });
        this.props.onLoad();
      }
    }, 0);
  }

  componentDidMount() {
    setTimeout(() => {
      if (!this.state.loaded) {
        this.onLoad();
      }
    }, LOAD_IMAGE_TIMEOUT);
  }

  componentWillUnmount() {
    window.clearTimeout(this.onLoadTimeout);
  }

  render() {
    const { image, fallbackImage } = this.props;

    if (fallbackImage) {
      return (
        <ImageContainer loaded={this.state.loaded}>
          <Image src={fallbackImage} onLoad={this.onLoad} />
          <Image src={image} visible={this.state.externalImageLoaded} onLoad={() => this.setState({ externalImageLoaded: true })} />
        </ImageContainer>
      );
    }

    return (
      <ImageContainer loaded={this.state.loaded}>
        <Image src={image} onLoad={this.onLoad} />
      </ImageContainer>
    );
  }
}

export default hot(module)(StageImage);
