import React from 'react';
import { hot } from 'react-hot-loader';
import styled, { keyframes } from 'styled-components';
import PropTypes from 'prop-types';
import { StandardButton, Footnote } from '../../styles';
import Arrow from '../misc/Arrow';
import { uaTriggerEvent } from '../../services/TrackingService';

const Container = styled.div`
  top: 0;
  width: 100%;
  pointer-events: ${props => (props.visible ? 'auto' : 'none')};
  opacity: ${props => (props.visible ? 1 : 0)};
  transform: ${props => (props.visible ? 'none' : 'translateX(-20px)')};
  transition: ${props => (props.visible ? 'all 0.25s ease-out 0.1s' : 'none')};
`;

const Trenner = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
`;

const Step = styled.div`
  position: relative;
  float: left;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #191919;
  background: white;
  text-transform: uppercase;
  padding-right: 25px;
`;

const TrennerLinie = styled.div`
  position: absolute;
  width: 100%;
  height: 2px;
  background: #d5d5d5;
  top: calc(50% - 1px);
`;

const ButtonContainer = styled.div`
  display: inline-block;
  position: relative;
  background: white;
  padding-right: 9px;
`;

const AnswerButton = styled(StandardButton)`
  margin-right: 16px;
  padding: 18px 41px;
`;

const NextQuestionButton = styled(StandardButton)`
  margin-right: 16px;
  background: #910a2d;
`;

const TextArea = styled.div`
  position: relative;
  height: 280px;
`;

const TippArrowContainer = styled.div`
  position: absolute;
  top: calc(50% - 25px);
  left: ${props => (props.right ? 'auto' : '-65px')};
  right: ${props => (props.right ? '-65px' : 'auto')};
  opacity: ${props => (props.clickable ? 1 : 0.5)};
  width: 40px;
  height: 50px;
  padding: 10px 17px;
  box-sizing: border-box;
  cursor: ${props => (props.clickable ? 'pointer' : 'auto')};
`;

const Question = styled.div`
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  font-size: 24px;
  line-height: 1.54;
  color: #191919;
  opacity: ${props => (props.visible ? 1 : 0)};
  transform: ${props => (props.visible ? 'translateY(-50%)' : 'translateY(calc(-50% - 10px))')};
  transition: ${props => (props.visible ? 'none' : 'all 0.2s ease-out')};
`;

const fadeInFromBottom = keyframes`
  from {opacity: 0; transform: translateY(calc(-50% + 10px));}
  to {opacity: 1; transform: translateY(-50%);}
`;

const Tipp = styled.div`
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  font-size: 16px;
  line-height: 1.75;
  color: #191919;
  /*opacity: ${props => (props.visible ? 1 : 0)};
  transform: ${props => (props.visible ? 'translateY(-50%)' : 'translateY(calc(-50% + 15px))')};
  transition: ${props => (props.visible ? 'all 0.25s ease-out 0.1s' : 'none')};*/
  animation: ${fadeInFromBottom} cubic-bezier(0.11, 0.35, 0.35, 0.98) 0.5s;
  animation-fill-mode: forwards;
`;

const TippStep = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.29;
  letter-spacing: 1.87px;
  color: #191919;
`;

const TippFootnote = styled(Footnote)`
  position: absolute;
  bottom: 90px;
`;

class RangeQuestions extends React.Component {
  static propTypes = {
    visible: PropTypes.bool,
    rangeData: PropTypes.object,
    reset: PropTypes.func,
    finish: PropTypes.func,
  };

  success;

  constructor(props) {
    super(props);
    this.state = { questionStepIndex: 0, tippIndex: 0, answer: 0 };
    this.previousTipp = this.previousTipp.bind(this);
    this.nextTipp = this.nextTipp.bind(this);
    this.nextQuestion = this.nextQuestion.bind(this);
    this.reset = this.reset.bind(this);
    this.answerQuestion = this.answerQuestion.bind(this);
    this.onFinishButtonClick = this.onFinishButtonClick.bind(this);
  }

  previousTipp() {
    const { tippIndex } = this.state;
    if (tippIndex > 0) this.setState({ tippIndex: this.state.tippIndex - 1 });
  }

  nextTipp(tipps) {
    const { tippIndex } = this.state;
    if (tippIndex < tipps.length - 1) this.setState({ tippIndex: this.state.tippIndex + 1 });
  }

  nextQuestion() {
    this.setState({ questionStepIndex: this.state.questionStepIndex + 1, answer: 0, tippIndex: 0 });
  }

  answerQuestion(answerIndex, currentQuestionStep) {
    const tipps = answerIndex === 1 ? currentQuestionStep.Tipps.yes : currentQuestionStep.Tipps.no;
    const trackingLabel = 'Question ' + (this.state.questionStepIndex + 1) + ', Answer: ' + (answerIndex === 1 ? 'yes' : 'no');

    this.success = this.getSuccess(answerIndex === 1);

    uaTriggerEvent('Range Questions Event', 'Answered', trackingLabel);
    if (tipps.length === 0) {
      this.nextQuestion();
    } else {
      this.setState({ answer: answerIndex });
    }
  }

  getSuccess(answerIsYes) {
    const { questionStepIndex } = this.state;
    const currentQuestionStep = this.props.rangeData.steps[questionStepIndex];

    if ('MatchAnswer' in currentQuestionStep) {
      switch (currentQuestionStep.MatchAnswer) {
        case 'yes':
          return answerIsYes;
        case 'no':
          return !answerIsYes;
      }
    }
  }

  reset() {
    this.props.reset();
    this.setState({ questionStepIndex: 0, tippIndex: 0, answer: 0 });
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.visible && this.props.visible) {
      uaTriggerEvent('Range Questions Event', 'Started');
    }
    if (prevProps.visible && !this.props.visible) {
      const lastStep = this.state.questionStepIndex >= this.props.rangeData.steps.length - 1;
      if (lastStep) {
        uaTriggerEvent('Range Questions Event', 'Finished');
      } else {
        uaTriggerEvent('Range Questions Event', 'Cancelled');
      }
      this.reset();
    }
  }

  componentWillUnmount() {
    if (this.props.visible) uaTriggerEvent('Range Questions Event', 'Cancelled');
  }

  onFinishButtonClick() {
    this.props.finish(this.success);
  }

  render() {
    const { visible, rangeData } = this.props;
    const { questionStepIndex, tippIndex, answer } = this.state;
    const { steps, labels } = rangeData;
    const questionStepAmount = steps.length;
    const currentQuestionStep = steps[questionStepIndex];
    const tipps = answer !== 0 ? (answer === 1 ? currentQuestionStep.Tipps.yes : currentQuestionStep.Tipps.no) : null;
    const tippText = tipps && tipps[tippIndex].text;
    const tippFootnote = tipps && tipps[tippIndex].footnote;
    const leftArrowClickable = tippIndex > 0;
    const rightArrowClickable = tipps && tippIndex < tipps.length - 1;

    return (
      <Container visible={visible}>
        <Trenner>
          <TrennerLinie />
          {answer === 0 && (
            <Step>
              {labels.step} {questionStepIndex + 1} {labels.of} {questionStepAmount}
            </Step>
          )}
          {answer > 0 && <Step>{labels.tippHeadline}</Step>}
        </Trenner>
        <TextArea>
          <Question visible={answer === 0}>{currentQuestionStep.question}</Question>
          <Tipp visible={answer !== 0} key={answer + '' + tippIndex}>
            {tippText}
          </Tipp>
          {answer !== 0 && (
            <TippArrowContainer clickable={leftArrowClickable} onClick={this.previousTipp}>
              <Arrow dark direction="left" />
            </TippArrowContainer>
          )}
          {answer !== 0 && (
            <TippArrowContainer
              right
              clickable={rightArrowClickable}
              onClick={() => {
                this.nextTipp(tipps);
              }}
            >
              <Arrow dark />
            </TippArrowContainer>
          )}
          {answer > 0 && (
            <TippStep>
              {tippIndex + 1} / {tipps.length}
            </TippStep>
          )}
        </TextArea>
        <Trenner>
          <TrennerLinie />
          <ButtonContainer>
            {answer === 0 && <AnswerButton onClick={() => this.answerQuestion(1, currentQuestionStep)}>{labels.yes}</AnswerButton>}
            {answer === 0 && <AnswerButton onClick={() => this.answerQuestion(2, currentQuestionStep)}>{labels.no}</AnswerButton>}
            {answer !== 0 && questionStepIndex < questionStepAmount - 1 && (
              <NextQuestionButton onClick={this.nextQuestion}>{labels.nextQuestion}</NextQuestionButton>
            )}
            {answer !== 0 && questionStepIndex >= questionStepAmount - 1 && (
              <NextQuestionButton onClick={this.onFinishButtonClick}>{labels.viewResult}</NextQuestionButton>
            )}
          </ButtonContainer>
        </Trenner>
        {tippFootnote && <TippFootnote>{tippFootnote}</TippFootnote>}
      </Container>
    );
  }
}

export default hot(module)(RangeQuestions);
