import React from 'react';
import { hot } from 'react-hot-loader';
import styled from 'styled-components';

const Container = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
`;

const Text = styled.div`
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
`;

class LoadingStep extends React.Component {
  render() {
    return (
      <Container>
        <Text>Loading</Text>
      </Container>
    );
  }
}

export default hot(module)(LoadingStep);
