import { createSelector } from 'reselect';

const getNavigation = state => state.navigation;

export const getAllSteps = createSelector(
  [getNavigation],
  navigation => {
    const { steps } = navigation;
    return steps;
  }
);

export const getCurrentStep = createSelector(
  [getNavigation],
  navigation => {
    const { steps } = navigation;
    return steps.find(s => s.active);
  }
);

export const getCurrentStepIndex = createSelector(
  [getNavigation],
  navigation => {
    const { steps } = navigation;
    return steps.findIndex(s => s.active);
  }
);
