import * as DATA from '../../constants/dataConstants';
import React from 'react';
import { hot } from 'react-hot-loader';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import RangeSliderOld from './RangeSliderOld';
import { StandardButton } from '../../styles';
import ChargeIcon from '../../assets/images/icons/charging-charge.png';
import DistanceIcon from '../../assets/images/icons/charging-distance.png';
import { uaTriggerEvent } from '../../services/TrackingService';

const Container = styled.div`
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: ${props => (props.visible ? 'auto' : 'none')};
  opacity: ${props => (props.visible ? 1 : 0)};
  transform: ${props => (props.visible ? 'none' : 'translateX(-20px)')};
  transition: ${props => (props.visible ? 'all 0.25s ease-out 0.1s' : 'none')};
`;

const QuestionContainer = styled.div`
  pointer-events: ${props => (props.visible ? 'auto' : 'none')};
  opacity: ${props => (props.visible ? 1 : 0)};
  transform: ${props => (props.visible ? 'none' : 'translateX(20px)')};
  transition: all 0.25s ease-out;
`;

const ResultsContainer = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: calc(100% - 100px);
  pointer-events: ${props => (props.visible ? 'auto' : 'none')};
  opacity: ${props => (props.visible ? 1 : 0)};
  transform: ${props => (props.visible ? 'none' : 'translateX(-20px)')};
  transition: all 0.25s ease-out 0.1s;
  z-index: 2;
`;

const Step = styled.div`
  font-size: 14px;
  font-weight: bold;
  line-height: 1.29;
  letter-spacing: 1.87px;
  text-transform: uppercase;
  margin-bottom: 20px;
`;

const Question = styled.div`
  height: 100px;
  font-size: 24px;
  line-height: 1.54;
  color: #191919;
  margin-bottom: 60px;
`;

const EasyCoverage = styled(Question)`
  margin-top: -40px;
`;

const Distance = styled.div`
  margin-top: 100px;
  font-size: 60px;
  line-height: 0.62;
  color: #191919;
  pointer-events: none;
`;

const ButtonContainer = styled.div`
  position: absolute;
  bottom: 0;
`;

const Button = styled(StandardButton)`
  margin-right: 17px;
`;

const RecommendationContainer = styled.div`
  position: absolute;
  bottom: 0;
`;

const RecommendationHeadline = styled.div`
  float: left;
  font-weight: bold;
  margin: 35px 0 10px 0;
  font-size: 16px;
  line-height: 42px;
`;

const RecommendationCopy = styled.div`
  float: left;
  font-size: 16px;
  line-height: 28px;
`;

const ChargingInfoContainer = styled.div`
  float: left;
  width: 100%;
  img {
    float: left;
    height: 40px;
    margin: 20px 25px 10px 0;
  }
  margin-bottom: 30px;
`;

const ChargingInfoDescription = styled.div`
  font-size: 14px;
  line-height: 2.36;
  color: #6e6e6e;
`;

const ChargingInfoValue = styled.div`
  font-size: 24px;
  color: #191919;
`;

class ChargingQuestions extends React.Component {
  // uaTriggerEvent('Range Questions Event', 'Answered', trackingLabel);
  // Start, Cancel, Finish

  static propTypes = {
    chargingInfos: PropTypes.object,
    distanceUnit: PropTypes.string,
    visible: PropTypes.bool,
    reset: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.reset = this.reset.bind(this);
    this.nextStep = this.nextStep.bind(this);
    this.previousStep = this.previousStep.bind(this);
    this.onSliderChange = this.onSliderChange.bind(this);
    this.distanceValueSets = {
      km: { sliderMax: 60, fullTankRange: 200, easyThreshold: 100 },
      miles: { sliderMax: 40, fullTankRange: 124, easyThreshold: 62 },
    };
    const distanceValues = this.props.distanceUnit === DATA.DATA_UNIT_DISTANCE_MILES ? this.distanceValueSets.miles : this.distanceValueSets.km;
    this.state = { step: 0, resultsReached: false, answers: [1, 1, 1], distanceValues, easyCoverage: false, isMinimumDistance: false };
  }

  reset() {
    const distanceValues = this.props.distanceUnit === DATA.DATA_UNIT_DISTANCE_MILES ? this.distanceValueSets.miles : this.distanceValueSets.km;
    const sliderInitialValue = Math.floor(distanceValues.sliderMax / 2);
    this.setState({
      step: 0,
      resultsReached: false,
      answers: [sliderInitialValue, sliderInitialValue, sliderInitialValue],
      distanceValues,
      easyCoverage: false,
      isMinimumDistance: false,
    });
  }

  previousStep() {
    const { step, resultsReached } = this.state;
    if (resultsReached) {
      this.props.reset();
    } else if (step > 0) {
      this.setState({ step: step - 1 });
    }
  }

  nextStep() {
    const { step } = this.state;
    const stepAmount = this.props.chargingInfos.steps.length;
    if (step < stepAmount - 1) {
      this.setState({ step: step + 1 });
    } else if (step > 0) {
      const { answers, distanceValues } = this.state;
      const isMinimumDistance = answers.find(a => a >= distanceValues.sliderMax) !== undefined;
      const distanceSum = answers.reduce((a, b) => a + b);
      //Tracking
      const distanceStep = this.state.distanceValues.sliderMax;
      const fromDistance = Math.floor(distanceSum / distanceStep) * distanceStep;
      const approxDistance = fromDistance + '-' + (fromDistance + distanceStep);
      const trackingLabel = 'Distance: ' + approxDistance + (this.props.distanceUnit === DATA.DATA_UNIT_DISTANCE_MILES ? ' miles' : ' km');
      uaTriggerEvent('Charging Questions Event', 'Finished', trackingLabel);

      const easyCoverage = distanceSum < distanceValues.easyThreshold;
      this.setState({ resultsReached: true, easyCoverage, isMinimumDistance });
    }
  }

  onSliderChange(newValue) {
    const { answers } = this.state;
    answers[this.state.step] = newValue;
    this.setState({ answers });
  }

  componentDidMount() {
    this.reset();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.visible && this.props.visible) {
      uaTriggerEvent('Charging Questions Event', 'Started');
    }

    if (prevProps.visible && !this.props.visible && !this.state.resultsReached) {
      uaTriggerEvent('Charging Questions Event', 'Cancelled');
    }

    if (prevProps.visible !== this.props.visible) {
      this.reset();
    }
  }

  componentWillUnmount() {
    if (this.props.visible && !this.state.resultsReached) {
      uaTriggerEvent('Charging Questions Event', 'Cancelled');
    }
  }

  render() {
    const { step, answers, resultsReached, distanceValues, easyCoverage, isMinimumDistance } = this.state;
    const { chargingInfos, visible, distanceUnit } = this.props;
    const { steps, labels, rangeInput, result } = chargingInfos;
    const stepText = labels.step + ' ' + (step + 1) + ' ' + labels.of + ' ' + steps.length;
    const unit = this.props.distanceUnit === DATA.DATA_UNIT_DISTANCE_MILES ? 'miles' : 'km';

    const distanceSum = answers.reduce((a, b) => a + b);
    let chargeText = '-';
    if (distanceSum > 0) {
      const chargeAfterDays = Math.floor(distanceValues.fullTankRange / distanceSum);
      if (chargeAfterDays <= 1) {
        chargeText = result.rechargeNeededResultSingular;
      } else {
        const preUnit = isMinimumDistance ? result.dailyDistancePreUnitMin + ' ' : result.dailyDistancePreUnitApprox + ' ';
        if (result.rechargeNeededResultPlural !== null) chargeText = preUnit + result.rechargeNeededResultPlural.replace('[days]', '' + chargeAfterDays);
      }
    }
    return (
      <Container visible={visible}>
        <QuestionContainer visible={!resultsReached}>
          <Step>{stepText}</Step>
          <Question>{steps[step]}</Question>
          <Distance>
            {answers[step] >= distanceValues.sliderMax && '> '}
            {answers[step]} {unit}
          </Distance>
          <RangeSliderOld
            key={visible + step}
            range={{ from: 0, to: distanceValues ? distanceValues.sliderMax : 50 }}
            value={answers[step]}
            onChange={this.onSliderChange}
            leftLabel={rangeInput.leftLabel}
            rightLabel={rangeInput.rightLabel}
          />
        </QuestionContainer>
        <ResultsContainer visible={resultsReached}>
          {easyCoverage && <EasyCoverage>{result.successMessage}</EasyCoverage>}
          <ChargingInfoContainer>
            <img src={DistanceIcon} />
            <span>
              <ChargingInfoDescription>{result.dailyDistanceText}</ChargingInfoDescription>
              <ChargingInfoValue>
                {isMinimumDistance && result.dailyDistancePreUnitMin + ' '}
                {distanceSum} {distanceUnit === DATA.DATA_UNIT_DISTANCE_MILES ? 'miles' : 'km'}
              </ChargingInfoValue>
            </span>
          </ChargingInfoContainer>
          <ChargingInfoContainer>
            <img src={ChargeIcon} />
            <span>
              <ChargingInfoDescription>{result.rechargeNeededText}</ChargingInfoDescription>
              <ChargingInfoValue>{chargeText}</ChargingInfoValue>
            </span>
          </ChargingInfoContainer>
          <RecommendationContainer>
            <RecommendationHeadline>{result.recommendationHeadline}</RecommendationHeadline>
            <RecommendationCopy>{result.recommendationCopy}</RecommendationCopy>
          </RecommendationContainer>
        </ResultsContainer>
        <ButtonContainer>
          <Button inactive={step === 0} onClick={this.previousStep}>
            {resultsReached ? labels.close : labels.previousStep}
          </Button>
          {!resultsReached && <Button onClick={this.nextStep}>{labels.nextStep}</Button>}
        </ButtonContainer>
      </Container>
    );
  }
}

export default hot(module)(ChargingQuestions);
