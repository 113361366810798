import * as ACTION from '../constants/actionConstants';
import * as NAVIGATION from '../constants/navigationConstants';
import chargingDetailHome from '../assets/images/modals/chargingDetailHome.jpg';
import chargingDetailOnTheGo from '../assets/images/modals/chargingDetailOnTheGo.jpg';

const initialState = {
  interiorModalIsOpen: false,
  rangeCalculation: {
    counter: {
      title: 'Ihre tägliche Fahrstrecke',
      limit: 200,
      unit: 'KM',
      copyBelowLimit: ['Mit einer täglich zurückgelegten Distanz von ', ', können Ihre alltäglichen Entfernungen leicht bewältigt werden.'],
      copyAboveLimit: [
        'Mit einer täglich zurückgelegten Distanz von ',
        ', müssen Sie einmal pro Tag aufladen. Das rasche Wachstum der öffentlichen Ladestationen ermöglicht es Ihnen, den Ladevorgang leicht in Ihr tägliches Leben zu integrieren.',
      ],
    },
    dailyStations: [
      { title: 'Arbeit', range: { from: 0, to: 100, step: 25 }, currentValue: 50 },
      { title: 'Einkaufen', range: { from: 0, to: 100, step: 25 }, currentValue: 0 },
      { title: 'Andere Aktivitäten', range: { from: 0, to: 100, step: 25 }, currentValue: 0 },
    ],
  },
  chargingCalculation: {
    counter: {
      title: 'geladen in',
      unit: 'h',
    },
    infoModalOpen: false,
    situations: [
      {
        title: 'Zu Hause',
        learnMoreLinkText: 'Mehr Infos über das Aufladen zu Hause',
        selected: true,
        options: [
          { name: 'Wallbox (AC)', value: '11kW', selected: true, chargingValues: { percentLoaded: 100, time: 5.75 } },
          { name: 'Steckdose', value: '4.6kw', selected: false, chargingValues: { percentLoaded: 100, time: 14.3 } },
        ],
        infoModal: {
          title: 'Zu Hause laden',
          image: chargingDetailHome,
          copy:
            'Sofern die Gegebenheiten es zulassen bietet Laden zu Hause den höchsten Komfort, da der Ladepunkt auf dem Privatgelände garantiert ist. Sie laden Ihr Fahrzeug genau wie Ihr Smartphone einfach über Nacht, während Sie schlafen, sodass Ihr Fahrzeug am Morgen fahrbereit ist.',
          columns: [
            {
              title: 'Wallbox (AC)',
              copy:
                'Um Zeit zu sparen und sicherzustellen, dass Sie die sicherste Lademethode nutzen, können Sie eine Wallbox für das Laden zu Hause installieren lassen.',
              bulletPoints: ['Ladezeit: 5,75 Stunden', 'Ladung: 100 %', 'Leistung: 11 kW'],
            },
            {
              title: 'Steckdose',
              copy:
                'Sie können den Mazda MX-30 an Ihre normale Steckdose zu Hause anschließen, obwohl dies die langsamste Art des Aufladens ist und nicht als normale Art des Aufladens empfohlen wird.',
              bulletPoints: ['Ladezeit: 14,3 Stunden', 'Ladung: 100 %', 'Leistung: 4,6 kW'],
            },
          ],
        },
      },
      {
        title: 'Unterwegs',
        learnMoreLinkText: 'Mehr Infos über das Aufladen unterwegs',
        selected: false,
        options: [
          { name: 'Schnellladestation', value: '55kW', selected: true, chargingValues: { percentLoaded: 80, time: 0.5 } },
          { name: 'Normale Ladestation', value: '22kW', selected: false, chargingValues: { percentLoaded: 100, time: 4.5 } },
        ],
        infoModal: {
          title: 'Fahrzeug unterwegs laden',
          image: chargingDetailOnTheGo,
          copy:
            'Öffentliche Ladestationen befinden sich üblicherweise entlang von Straßen und werden meist von Stromanbietern betrieben. Weitere finden Sie an Einkaufszentren, Restaurants und auf Parkplätzen. Diese werden meist von verschiedenen privaten Unternehmen betrieben.',
          columns: [
            {
              title: 'Schnellladestation (DC)',
              copy:
                'Das Laden an einer Schnellladestation ist die bevorzugte Methode auf langen Strecken. Diese sind vorwiegend entlang der Autobahn zu finden. Bis Ende 2020 sollen in Deutschland 7.000 Schnellladestationen eingerichtet werden.',
              bulletPoints: ['Ladezeit: 30-40 Minuten', 'Ladung: 80 %', 'Leistung: 55 kW'],
            },
            {
              title: 'Normale Ladestation (AC)',
              copy:
                'An den meisten öffentlichen Ladestationen können Sie Ihr Fahrzeug mit normaler Geschwindigkeit laden. Bis Ende 2020 sollen in Deutschland 36.000 öffentliche Ladestationen eingerichtet werden.',
              bulletPoints: ['Ladezeit: 4,5 Stunden', 'Ladung: 100 %', 'Leistung: 22 kW'],
            },
          ],
        },
      },
    ],
  },
};

function changeRangeCalculationValue(rangeCalculation, payload) {
  const newStations = [...rangeCalculation.dailyStations];
  newStations[payload.station].currentValue = payload.value;
  return {
    ...rangeCalculation,
    dailyStations: newStations,
  };
}

function selectChargingSituationAndOption(chargingCalculation, payload) {
  let targetOptions = [...chargingCalculation.situations[payload.situation].options];
  targetOptions =
    typeof payload.option != 'undefined'
      ? targetOptions.map((option, index) => {
          return { ...option, selected: payload.option == index };
        })
      : targetOptions;
  return {
    ...chargingCalculation,
    situations: [...chargingCalculation.situations].map((situation, index) =>
      payload.situation == index ? { ...situation, selected: true, options: targetOptions } : { ...situation, selected: false }
    ),
  };
}

function closeChargingModal(chargingCalculation) {
  return { ...chargingCalculation, infoModalOpen: false };
}

function openChargingModal(chargingCalculation) {
  return { ...chargingCalculation, infoModalOpen: true };
}

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ACTION.CHANGE_RANGE_CALCULATION_VALUE:
      return {
        ...state,
        rangeCalculation: changeRangeCalculationValue(state.rangeCalculation, payload),
      };
    case ACTION.SELECT_CHARGING_SITUATION:
    case ACTION.SELECT_CHARGING_OPTION:
      return {
        ...state,
        chargingCalculation: selectChargingSituationAndOption(state.chargingCalculation, payload),
      };
    case ACTION.OPEN_CHARGING_MODAL:
      return {
        ...state,
        chargingCalculation: openChargingModal(state.chargingCalculation),
      };
    case NAVIGATION.GO_TO_INDEX:
    case ACTION.CLOSE_CHARGING_MODAL:
      return {
        ...state,
        chargingCalculation: closeChargingModal(state.chargingCalculation),
      };
    case ACTION.TOGGLE_INTERIOR_MODAL:
      return {
        ...state,
        interiorModalIsOpen: payload,
      };
  }
  return state;
};
