import * as NAVIGATION from '../../constants/navigationConstants';
import React from 'react';
import { hot } from 'react-hot-loader';
import PropTypes from 'prop-types';
import { uaInitialize, uaTriggerPageView, uaSetLanguage } from '../../services/TrackingService';

class UniversalAnalytics extends React.Component {
  static propTypes = {
    currentStep: PropTypes.object,
    currentLanguage: PropTypes.string,
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    uaInitialize();
    uaSetLanguage(this.props.currentLanguage);
  }

  componentDidUpdate(prevProps) {
    const lastStepType = prevProps.currentStep.type;
    const thisStepType = this.props.currentStep.type;
    const lastStepId = prevProps.currentStep.id;
    const thisStepId = this.props.currentStep.id;

    if (lastStepId !== thisStepId) {
      let sessionControl = null;
      if (lastStepType === NAVIGATION.STEP_TYPE_IDLE) sessionControl = 'start';
      else if (thisStepType === NAVIGATION.STEP_TYPE_IDLE) sessionControl = 'end';
      if (lastStepType !== NAVIGATION.STEP_TYPE_LOADING) uaTriggerPageView('/' + thisStepId, thisStepId, sessionControl);
    }

    const lastLanguage = prevProps.currentLanguage;
    const thisLanguage = this.props.currentLanguage;
    if (lastLanguage !== thisLanguage) uaSetLanguage(thisLanguage);
  }

  render() {
    return null;
  }
}

export default hot(module)(UniversalAnalytics);
