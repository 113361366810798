import React from 'react';
import { hot } from 'react-hot-loader';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Video = styled.video`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  opacity: ${p => (p.ready ? 1 : 0)};
  transition: opacity 0.7s linear;
`;

const Image = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-image: url(${props => props.image});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
`;

class ModalVideoWithPoster extends React.Component {
  static propTypes = {
    posterImage: PropTypes.string,
    video: PropTypes.string,
    onVideoEnded: PropTypes.func,
    controls: PropTypes.bool,
    dontLoop: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.onVideoCanPlay = this.onVideoCanPlay.bind(this);
    this.videoRef = React.createRef();
    this.state = { videoReady: false };
  }

  onVideoCanPlay() {
    this.setState({ videoReady: true });
    this.videoRef.current.play();
  }

  componentDidMount() {
    if (this.videoRef.current) {
      this.videoRef.current.addEventListener('canplaythrough', this.onVideoCanPlay);
      if (this.props.onVideoEnded) this.videoRef.current.addEventListener('ended', this.props.onVideoEnded);
    }
  }

  render() {
    const { videoReady } = this.state;
    const { posterImage, video, controls, dontLoop } = this.props;
    return (
      <>
        <Image image={posterImage} />
        <Video
          loop={!dontLoop}
          preload="auto"
          muted
          src={video}
          type="video/mp4"
          ref={this.videoRef}
          ready={videoReady}
          controls={!!controls}
          controlsList="nofullscreen nodownload noremoteplayback"
          disablePictureInPicture
        />
      </>
    );
  }
}

export default hot(module)(ModalVideoWithPoster);
