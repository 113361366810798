import * as NAVIGATION from '../../constants/navigationConstants';
import * as DATA from '../../constants/dataConstants';
import React from 'react';
import { hot } from 'react-hot-loader';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { H2, infoSideBarWidth, StandardButton, Footnote, colors } from '../../styles';
import RangeQuestions from '../sideBar/RangeQuestions';
import ChargingQuestions from '../sideBar/ChargingQuestions';
import SideBarButton from './SideBarButton';
import QRLink from '../misc/QRLink';
import TextContent from '../misc/TextContent';
import PlusDot from '../stage/PlusDot';
import MazdaLogoImage from '../../assets/images/mazdaLogo.png';
import RangeQuestionsResult from './RangeQuestionsResult';

const StyledH2 = styled(H2)`
  margin: 0 0 45px 0;
`;

const MazdaLogo = styled.img`
  position: absolute;
  right: 0;
  top: 0;
  width: 100px;
  padding: 45px 40px;
  pointer-events: none;
`;

const Bar = styled.div`
  position: relative;
  box-sizing: border-box;
  float: right;
  width: ${infoSideBarWidth}px;
  height: 930px;
  padding: 225px 100px 65px;
  background: white;
  text-align: left;
  transform: ${props => (props.hide ? 'translateX(100%)' : 'none')};
  transition: transform 1s ${props => (props.hide ? 'cubic-bezier(0.51, 0.04, 0.93, 0.53) 1s' : 'cubic-bezier(.14,.68,.48,.99)')};
`;

const BarInnerContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const BottomContent = styled.div`
  position: absolute;
  bottom: 0;
`;

/*const fadeIn = keyframes`
  from {opacity: 0; transform: translateX(-25px);}
  to {opacity: 1; transform: translateX(0);}
`;

const ContentArea = styled.div`
  height: 100%;
  animation: ${fadeIn} cubic-bezier(0.11, 0.35, 0.35, 0.98) 1s;
  animation-fill-mode: forwards;
`;*/

const Content = styled.div`
  position: absolute;
  width: 440px;
  pointer-events: ${props => (!props.visible ? 'none' : 'auto')};
  height: 100%;
`;

const InfoContent = styled(Content)`
  opacity: ${props => (props.visible ? 1 : 0)};
  transform: ${props => (props.visible ? 'none' : 'translate(20px)')};
  transition: ${props => (props.visible ? 'none' : 'all 0.25s ease-out')};
  pointer-events: ${props => (!props.visible ? 'none' : 'auto')};
`;

const InvitationParagraph = styled.p`
  font-size: 16px;
  margin-bottom: 16px;
`;

const QrSection = styled.div`
  position: relative;
  height: 190px;
`;

const QrCode = styled.div`
  position: absolute;
  width: 130px;
  height: 130px;
  top: 50%;
  transform: translateY(-50%);
`;

const QrText = styled.div`
  position: absolute;
  width: calc(100% - 160px);
  top: 50%;
  transform: translateY(-50%);
  left: 160px;
  font-size: 16px;
  line-height: 1.79;
  color: #191919;
`;
const QrHeadline = styled.div`
  font-weight: bold;
`;

const BackToStartButton = styled(StandardButton)`
  width: ${props => (props.alone ? '100%' : 'calc(100% - 80px)')};
  box-sizing: border-box;
  margin-top: ${props => (props.alone ? '0' : '21px')};
  margin-bottom: ${props => (props.alone ? '80px' : '0')};
  bottom: ${props => (props.alone ? '50%' : 'auto')};
  position: ${props => (props.alone ? 'absolute' : 'relative')};
  background-color: ${colors.darkRed};
  text-align: center;
  font-weight: normal;
`;

const CloseRangeButton = styled.div`
  position: absolute;
  left: -125px;
  top: -15px;
  cursor: pointer;
`;

const SidebarButtonContainer = styled.div`
  display: inline-block;
  position: relative;
  left: -33px;
  width: 540px;
  margin-top: 25px;
`;

class SideBar extends React.Component {
  static propTypes = {
    step: PropTypes.object,
    selectStageImageByIndex: PropTypes.func,
    returnToIdleStep: PropTypes.func,
    hide: PropTypes.bool,
    currentLanguage: PropTypes.string,
    buttonsVisible: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.renderSideBarButtons = this.renderSideBarButtons.bind(this);
    this.renderInivitationContent = this.renderInivitationContent.bind(this);
    this.startChargingQuestionsModule = this.startChargingQuestionsModule.bind(this);
    this.startRangeQuestionsModule = this.startRangeQuestionsModule.bind(this);
    this.resetRangeQuestionsModule = this.resetRangeQuestionsModule.bind(this);
    this.finishRangeQuestionsModule = this.finishRangeQuestionsModule.bind(this);
    this.resetChargingQuestionsModule = this.resetChargingQuestionsModule.bind(this);
    this.state = {
      rangeModuleStarted: false,
      rangeModuleFinished: false,
      rangeModuleSuccess: undefined,
      chargingModuleStarted: false,
    };
  }

  renderSideBarButtons(images) {
    if (images.length < 2 || typeof images[0].thumbnailUrl == 'undefined' || images[0].thumbnailUrl === null) return;
    return images.map((image, index) => {
      return (
        <SideBarButton
          key={index}
          index={index}
          selected={image.selected}
          image={image.thumbnailUrl}
          caption={image.name}
          onClick={() => {
            this.props.selectStageImageByIndex(index);
          }}
        />
      );
    });
  }

  startRangeQuestionsModule() {
    this.setState({ rangeModuleStarted: true });
  }

  resetRangeQuestionsModule() {
    this.setState({ rangeModuleStarted: false, rangeModuleFinished: false, rangeModuleSuccess: undefined });
  }

  finishRangeQuestionsModule(success) {
    this.setState({ rangeModuleFinished: true, rangeModuleSuccess: success });
  }

  startChargingQuestionsModule() {
    this.setState({ chargingModuleStarted: true });
  }

  resetChargingQuestionsModule() {
    this.setState({ chargingModuleStarted: false, rangeModuleFinished: false, rangeModuleSuccess: undefined });
  }

  renderInfoStepContent(step) {
    const { info } = step;
    return (
      <InfoContent key={step.id} visible={!this.state.rangeModuleStarted && !this.state.chargingModuleStarted}>
        {info && <StyledH2>{info.headline}</StyledH2>}
        <TextContent text={info.text} />
        {step.range && <StandardButton onClick={this.startRangeQuestionsModule}>{step.range.labels.start}</StandardButton>}
        {step.charging && <StandardButton onClick={this.startChargingQuestionsModule}>{step.charging.labels.start}</StandardButton>}
        {info && info.qrSidebar && info.qrSidebar.url && this.renderQrSection(info.qrSidebar)}
        {this.props.buttonsVisible && step.stage.images.length > 1 && (
          <SidebarButtonContainer>{this.renderSideBarButtons(step.stage.images)}</SidebarButtonContainer>
        )}
        {this.renderBottomContent(step)}
      </InfoContent>
    );
  }

  renderRangeContent(step) {
    return (
      <>
        <InfoContent
          key={step.id + 'range'}
          visible={this.state.rangeModuleStarted && !this.state.rangeModuleFinished}
          style={{ marginTop: '90px', transform: 'translate(0)' }}
        >
          <CloseRangeButton onClick={this.resetRangeQuestionsModule}>
            <PlusDot visible={this.state.rangeModuleStarted} type="close" />
          </CloseRangeButton>
          <RangeQuestions
            visible={this.state.rangeModuleStarted}
            rangeData={step.range}
            reset={this.resetRangeQuestionsModule}
            finish={this.finishRangeQuestionsModule}
          />
        </InfoContent>
        {this.state.rangeModuleFinished && (
          <InfoContent key={step.id + 'rangeResult'} visible={this.state.rangeModuleStarted && this.state.rangeModuleFinished}>
            <RangeQuestionsResult success={this.state.rangeModuleSuccess} rangeResultData={step.range.result} />
            <StandardButton onClick={this.resetRangeQuestionsModule} style={{ position: 'absolute', left: 0, bottom: 0, backgroundColor: colors.darkRed }}>
              {step.range.labels.close}
            </StandardButton>
          </InfoContent>
        )}
      </>
    );
  }

  renderChargingContent(step, currentLanguage) {
    const distanceUnit = currentLanguage === 'mmuk' ? DATA.DATA_UNIT_DISTANCE_MILES : DATA.DATA_UNIT_DISTANCE_KM;
    return (
      <Content key={step.id + 'charging'} visible={this.state.chargingModuleStarted}>
        <CloseRangeButton onClick={this.resetChargingQuestionsModule}>
          <PlusDot visible={this.state.chargingModuleStarted} type="close" />
        </CloseRangeButton>
        <ChargingQuestions
          visible={this.state.chargingModuleStarted}
          chargingInfos={step.charging}
          reset={this.resetChargingQuestionsModule}
          distanceUnit={distanceUnit}
        />
      </Content>
    );
  }

  renderInivitationContent(step) {
    const { service } = step;
    const showQrSection = service.qrSidebar.url !== '' && service.qrSidebar.url !== null;
    return (
      <InfoContent key={step.id} visible={true}>
        {service.sidebarHeadline && <StyledH2>{service.sidebarHeadline}</StyledH2>}
        {service.sidebarCopy && <InvitationParagraph>{service.sidebarCopy}</InvitationParagraph>}
        {showQrSection && this.renderQrSection(service.qrSidebar)}
        <BackToStartButton onClick={this.props.returnToIdleStep} alone={!showQrSection && !service.sidebarHeadline && !service.sidebarCopy}>
          {service.backToStartButton}
        </BackToStartButton>
      </InfoContent>
    );
  }

  renderQrSection(qrSidebar) {
    return (
      <QrSection>
        <QrCode>
          <QRLink url={qrSidebar.url} size={128} />
        </QrCode>
        <QrText>
          {qrSidebar.headline && <QrHeadline>{qrSidebar.headline}</QrHeadline>}
          {qrSidebar.description && qrSidebar.description}
        </QrText>
      </QrSection>
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.step.id !== prevProps.step.id) {
      this.setState({ rangeModuleStarted: false, rangeModuleFinished: false, chargingModuleStarted: false });
    }
  }

  renderBottomContent(step) {
    return (
      <div>
        <BottomContent>{step.info && step.info.fineprint && <Footnote>{step.info.fineprint}</Footnote>}</BottomContent>
      </div>
    );
  }

  render() {
    const { step, hide, currentLanguage } = this.props;
    return (
      <Bar hide={hide}>
        <MazdaLogo src={MazdaLogoImage} />
        <BarInnerContainer>
          {step.range !== undefined && this.renderRangeContent(step)}
          {step.charging !== undefined && this.renderChargingContent(step, currentLanguage)}
          {step.type === NAVIGATION.STEP_TYPE_INFO && this.renderInfoStepContent(step)}
          {step.type === NAVIGATION.STEP_TYPE_INVITE && this.renderInivitationContent(step)}
        </BarInnerContainer>
      </Bar>
    );
  }
}

export default hot(module)(SideBar);
