import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { StandardButton } from 'styles';
import { connect } from 'react-redux';

import ReplayIcon from 'assets/images/icons/replay.png';
import SkipIcon from 'assets/images/icons/skip.png';
import { LOAD_VIDEO_TIMEOUT } from 'constants/loadingConstants';

const ButtonContainer = styled.div`
  position: absolute;
  z-index: 11;
  top: 32px;
  left: 32px;
  opacity: ${props => (props.visible ? 1 : 0)};
  transform: ${props => (props.visible ? 'scale(1)' : 'scale(0.95)')};
  transform-origin: 30px 50%;
  transition: transform 300ms cubic-bezier(0.15, 0.68, 0.3, 1), opacity 200ms linear;
  transition-delay: ${props => (props.visible ? '50ms' : '0s')};
  pointer-events: ${props => (props.visible ? 'all' : 'none')};
`;

const Video = styled.video`
  position: absolute;
  z-index: 10;
  left: 0;
  height: 100%;
  width: 100%;
  transform: ${props => (props.visible ? 'scale(1.005)' : 'scale(1.08)')};
  opacity: ${props => (props.visible ? 1 : 0)};
  transition: transform 2000ms cubic-bezier(0.15, 0.68, 0.3, 1), opacity 400ms linear;
`;

const ButtonIcon = styled.span`
  display: inline-block;
  width: 22px;
  height: 22px;
  background-image: url("${props => props.src}");
  background-size: 100%;
  vertical-align: top;
  margin: -3px 20px 0 0;
`;

const SlimButton = styled(StandardButton)`
  padding-left: 20px;
  padding-right: 20px;
`;

class StageVideo extends React.Component {
  static propTypes = {
    src: PropTypes.string,
    playVideo: PropTypes.bool,
    onEnded: PropTypes.func,
    onClickReplay: PropTypes.func,
    fallbackImages: PropTypes.array,
    texts: PropTypes.object,
    buttonVisible: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = { playing: false, timeout: false };
  }

  skip = () => {
    this.props.onEnded();
  };

  replay = () => {
    this.setState({ playing: false });
    this.props.onClickReplay();
  };

  onError = () => {
    this.setState({ timeout: true });
    this.skip();
  };

  componentDidMount() {
    setTimeout(() => {
      if (this.props.playVideo && !this.state.playing) {
        this.setState({ timeout: true });
        this.skip();
      }
    }, LOAD_VIDEO_TIMEOUT);
  }

  render() {
    const { playing, posterLoaded } = this.state;
    const { src, playVideo, texts, fallbackImages, onEnded, buttonVisible } = this.props;
    return (
      <>
        <img src={fallbackImages[0].url} onLoad={() => this.setState({ posterLoaded: true })} style={{ position: 'absolute', opacity: 0 }} />
        {playVideo && (
          <Video
            poster={fallbackImages[0].url}
            src={src}
            autoPlay
            onPlay={() => this.setState({ playing: true })}
            onError={this.onError}
            onEnded={onEnded}
            visible={playing || posterLoaded}
            muted
          />
        )}
        <ButtonContainer visible={playVideo && buttonVisible && !this.state.timeout}>
          <SlimButton onClick={this.skip} inverted>
            <ButtonIcon src={SkipIcon} />
            {texts.skipHighlightsButtonText}
          </SlimButton>
        </ButtonContainer>
        <ButtonContainer visible={!playVideo && buttonVisible && !this.state.timeout}>
          <SlimButton onClick={this.replay} inverted>
            <ButtonIcon src={ReplayIcon} />
            {texts.replayHighlightsButtonText}
          </SlimButton>
        </ButtonContainer>
      </>
    );
  }
}

const mapStateToProps = state => ({
  texts: state.text.texts,
  buttonVisible: !state.interaction.interiorModalIsOpen,
});

export default connect(mapStateToProps)(StageVideo);
