import * as NAVIGATION from '../constants/navigationConstants';
import * as DATA from '../constants/dataConstants';

const initialState = {
  languages: [],
  texts: [],
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case DATA.DATA_TEXTS_LOAD_FULFILLED:
      return {
        ...state,
        languages: payload,
      };
    case NAVIGATION.SELECT_LANGUAGE: {
      let lang = payload;
      if (!state.languages.find(language => language.language == lang)) lang = 'mmuk';
      return {
        ...state,
        texts: JSON.parse(JSON.stringify(state.languages.find(language => language.language == lang).texts)),
      };
    }
  }
  return state;
};
