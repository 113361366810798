import * as ACTION from '../constants/actionConstants';

export function selectChargingSituation(situationIndex) {
  return dispatch =>
    dispatch({
      type: ACTION.SELECT_CHARGING_SITUATION,
      payload: {
        situation: situationIndex,
      },
    });
}

export function selectChargingOption(situationIndex, optionIndex) {
  return dispatch =>
    dispatch({
      type: ACTION.SELECT_CHARGING_OPTION,
      payload: {
        situation: situationIndex,
        option: optionIndex,
      },
    });
}

export function openChargingModal() {
  return dispatch =>
    dispatch({
      type: ACTION.OPEN_CHARGING_MODAL,
    });
}

export function closeChargingModal() {
  return dispatch =>
    dispatch({
      type: ACTION.CLOSE_CHARGING_MODAL,
    });
}

export function toggleInteriorModal(open) {
  return dispatch =>
    dispatch({
      type: ACTION.TOGGLE_INTERIOR_MODAL,
      payload: open,
    });
}

export function changeRangeCalculationValue(stationIndex, value) {
  return dispatch =>
    dispatch({
      type: ACTION.CHANGE_RANGE_CALCULATION_VALUE,
      payload: {
        station: stationIndex,
        value: value,
      },
    });
}
