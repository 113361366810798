import React from 'react';
import { hot } from 'react-hot-loader';
import PropTypes from 'prop-types';
import { colors } from '../../styles';
import QRCode from 'qrcode.react';

class QRLink extends React.Component {
  static propTypes = {
    url: PropTypes.string.isRequired,
    size: PropTypes.number.isRequired,
  };

  render() {
    const { url, size } = this.props;
    return <QRCode size={size} level="L" fgColor={colors.darkGrey} value={url} />;
  }
}

export default hot(module)(QRLink);
