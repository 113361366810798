import React from 'react';
import { hot } from 'react-hot-loader';
import PropTypes from 'prop-types';
import PlusDot from './PlusDot';
import { Container, ImageContainer, Image, CloseButtonContainer } from './Modal.style';
import styled from 'styled-components';
import HotSpotModalTextBlock from './HotSpotModalTextBlock';
import VideoWithPoster from './ModalVideoWithPoster';

export const TextBlockContainer = styled.div`
  text-align: left;
  padding: 42px 57px 60px;
`;

class HotSpotModal extends React.Component {
  static propTypes = {
    hotSpot: PropTypes.object,
    transitionState: PropTypes.string,
    close: PropTypes.func,
  };

  render() {
    const { hotSpot, transitionState, close } = this.props;
    const { video, image } = hotSpot;
    return (
      <Container transitionState={transitionState}>
        <ImageContainer>
          {!video && <Image transitionState={transitionState} image={image} />}
          {video && <VideoWithPoster video={video} posterImage={image} />}
        </ImageContainer>
        <TextBlockContainer>
          <HotSpotModalTextBlock headline={hotSpot.title} copy={hotSpot.copy} list={hotSpot.list} fineprint={hotSpot.fineprint} />
        </TextBlockContainer>
        <CloseButtonContainer onClick={close}>
          <PlusDot visible={transitionState == 'entered'} type="close" />
        </CloseButtonContainer>
      </Container>
    );
  }
}

export default hot(module)(HotSpotModal);
