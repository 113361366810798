import styled, { keyframes, css } from 'styled-components';
import BackgroundVideoPoster from '../../assets/videos/Poster.jpg';
import { H1, H2 } from 'styles';

export const fullsizeAbsolute = css`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

export const Container = styled.div`
  ${fullsizeAbsolute}
  overflow: hidden;
`;

export const Video = styled.video`
  display: ${props => (props.videoReady ? 'block' : 'none')};
  ${fullsizeAbsolute}
`;

const appear = keyframes`
   0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const VideoPoster = styled.div`
  ${fullsizeAbsolute}
  background-image: url(${BackgroundVideoPoster});
  background-size: cover;
  opacity: 0;
  animation: 1s ${appear} ease-out 2s;
  animation-fill-mode: forwards;
  backface-visibility: none;
`;

export const LanguageButton = styled.div`
  position: absolute;
  right: 60px;
  top: 20px;
  padding: 20px;
  font-size: 24px;
  color: white;
  cursor: pointer;
  svg {
    padding-left: 10px;
  }
`;

export const LanguageSelectionBar = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 360px;
  text-align: center;
  transform: ${props => (props.open ? 'none' : 'translateX(100%)')};
  background: white;
  transition: all 0.5s cubic-bezier(0.14, 0.61, 0.5, 0.99);
`;

export const CurrentLanguage = styled.div`
  height: 184px;
  border-bottom: solid 1px #d5d5d5;
  width: 100%;
  box-sizing: border-box;
  padding: 76px 0;
  font-size: 24px;
  color: #191919;
`;

export const CloseLanguagesButton = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
  padding: 10px;
  .cross {
    position: relative;
    width: 16px;
    height: 16px;
    div {
      position: absolute;
      background: #191919;
      width: 100%;
      height: 3px;
      top: 7px;
    }
    div.one {
      transform: rotate(45deg);
    }
    div.two {
      transform: rotate(-45deg);
    }
  }
`;

export const LanguageList = styled.div`
  height: 895px;
  width: 100%;
  box-sizing: border-box;
  padding: 50px 0;
  font-size: 16px;
  overflow-y: scroll;
`;

export const OverlayTopline = styled(H1)`
  position: absolute;
  color: white;
  left: 104px;
  top: 130px;
  font-size: 65px;
  letter-spacing: 4px;
`;

export const OverlayHeadline = styled(H1)`
  position: absolute;
  color: white;
  left: 100px;
  top: ${p => (p.moveUp ? '100px' : '190px')};
  font-size: 100px;
  line-height: 100px;
  font-weight: 100;
  letter-spacing: 7px;
  text-align: left;
`;

export const OverlaySubline = styled(OverlayTopline)`
  top: 260px;
`;

export const OverlayBottomText = styled(H2)`
  position: absolute;
  left: 100px;
  bottom: 60px;
  letter-spacing: 7px;
  font-size: 30px;
  color: white;
`;

export const LanguageListItem = styled.div`
  height: 44px;
  box-sizing: border-box;
  padding: 13px 0;
  width: 100%;
  cursor: pointer;
  color: ${props => (props.selected ? '#191919' : '#6e6e6e')};
  font-weight: ${props => (props.selected ? 'bold' : 'normal')};
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
`;
