import React from 'react';
import { hot } from 'react-hot-loader';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

class PreLoader extends React.Component {
  static propTypes = {
    steps: PropTypes.array,
  };

  getAssetUrlsFromSteps(steps) {
    const media = [];
    const addMedia = (url, type = 'image') => {
      if (url !== null && typeof url !== 'undefined') {
        media.push({ url: url, type: type });
      }
    };
    steps.forEach(step => {
      if (typeof step.stage !== 'undefined' && typeof step.stage.images !== 'undefined') {
        addMedia(step.stage.idleVideo, 'video');
        addMedia(step.stage.introVideo, 'video');
        step.stage.images.forEach(stageImage => {
          addMedia(stageImage.url);
          if (typeof stageImage.thumbnailUrl !== 'undefined') addMedia(stageImage.thumbnailUrl);
        });
      }
      if (typeof step.info !== 'undefined' && typeof step.info.hotSpots !== 'undefined') {
        step.info.hotSpots.forEach(hotSpot => {
          addMedia(hotSpot.image);
          addMedia(hotSpot.video, 'video');
          if (typeof hotSpot.slides !== 'undefined') {
            hotSpot.slides.forEach(slide => {
              addMedia(slide.image);
              addMedia(slide.video, 'video');
            });
          }
          if (typeof hotSpot.columns !== 'undefined') {
            hotSpot.columns.forEach(column => {
              addMedia(column.image);
            });
          }
        });
      }
    });
    return media;
  }

  renderPreLoadLinks(media) {
    return media.map((m, index) => <link key={index} rel={m.type === 'video' ? 'prefetch' : 'preload'} href={m.url} as={m.type} />);
  }

  render() {
    const { steps } = this.props;
    const urls = this.getAssetUrlsFromSteps(steps);
    return <Helmet>{this.renderPreLoadLinks(urls)}</Helmet>;
  }
}

export default hot(module)(PreLoader);
