import React from 'react';
import ReactDOM from 'react-dom';
import Page from './components/main/Page';
import { Provider } from 'react-redux';
import store from './store';

const root = document.getElementById('app');

ReactDOM.render(
  <Provider store={store}>
    <Page />
  </Provider>,
  root
);
