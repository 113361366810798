import React from 'react';
import { hot } from 'react-hot-loader';
import PropTypes from 'prop-types';
import PlusDot from './PlusDot';
import { Container, ImageContainer, Image, CloseButtonContainer, Fineprint } from './Modal.style';
import styled from 'styled-components';
import HotSpotModalTextBlock from './HotSpotModalTextBlock';
import { StyledH3 } from './Modal.style';
import { P2 } from '../../styles';

export const ColumnWrapper = styled.div`
  display: table;
  position: relative;
  text-align: left;
`;

export const ColumnContainer = styled.div`
  display: table-cell;
  width: 50%;
  padding: 45px 70px;
`;

export const CenteredFineprint = styled(Fineprint)`
  text-align: center;
  max-width: 330px;
  margin: 0 auto 45px;
`;

export const ColumnDivider = styled.div`
  position: absolute;
  width: 2px;
  height: calc(100% - 90px);
  margin-top: 45px;
  background: #d5d5d5;
`;

export const Abdunkler = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: black;
  opacity: 0.6;
`;

export const TopTextContainer = styled.div`
  position: absolute;
  top: 0;
`;

export const TopHeadline = styled(StyledH3)`
  margin-top: 80px;
  margin-bottom: 30px;
  position: relative;
  color: white;
`;

export const TopCopy = styled(P2)`
  position: relative;
  padding: 0px 240px;
  color: white;
`;

class HotSpotComparisonModal extends React.Component {
  static propTypes = {
    hotSpot: PropTypes.object,
    transitionState: PropTypes.string,
    close: PropTypes.func,
  };

  renderColumn(column) {
    return (
      <ColumnContainer>
        <HotSpotModalTextBlock headline={column.headline} copy={column.copy} list={column.list} fineprint={column.fineprint} />
      </ColumnContainer>
    );
  }

  render() {
    const { hotSpot, transitionState, close } = this.props;
    const { columns } = hotSpot;
    return (
      <Container transitionState={transitionState} wide={true}>
        <ImageContainer narrow={true}>
          <Image transitionState={transitionState} image={hotSpot.image} />
          <Abdunkler />
          <TopTextContainer>
            <TopHeadline>{hotSpot.title}</TopHeadline>
            <TopCopy>{hotSpot.topCopy}</TopCopy>
          </TopTextContainer>
        </ImageContainer>
        <ColumnWrapper>
          {this.renderColumn(columns[0])}
          <ColumnDivider />
          {this.renderColumn(columns[1])}
        </ColumnWrapper>
        {hotSpot.finePrint && <CenteredFineprint>{hotSpot.finePrint}</CenteredFineprint>}
        <CloseButtonContainer onClick={close}>
          <PlusDot visible={transitionState == 'entered'} type="close" />
        </CloseButtonContainer>
      </Container>
    );
  }
}

export default hot(module)(HotSpotComparisonModal);
