import React from 'react';
import { hot } from 'react-hot-loader';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { colors } from '../../styles';

const Container = styled.div`
  position: relative;
  margin: 20px 0 0 5px;
`;

const InputRange = styled.input`
  display: block;
  padding: 25px 0;
  width: 100%;
  background: none;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  ::-webkit-slider-runnable-track {
    width: 100%;
    height: 4px;
    cursor: pointer;
    background: ${colors.mediumLightGrey};
    border-radius: 4px;
    border: none;
    -webkit-appearance: none;
  }
  ::-webkit-slider-thumb {
    cursor: pointer;
    width: 25px;
    height: 25px;
    background-color: ${colors.darkRed};
    /*background-color: white;
    border: 8px solid ${colors.darkRed};*/
    border-radius: 13px;
    -webkit-appearance: none;
    box-shadow: none;
    margin-top: -11px;
  }
  :focus {
    outline: none;
  }
  overscroll-behavior-y: contain;
`;

const RangeContainer = styled.div`
  position: relative;
  width: 100%;
  float: left;
  top: 5px;
`;

const Value = styled.div`
  position: absolute;
  font-size: 12px;
  text-transform: uppercase;
  text-align: ${props => props.align};
  top: 55px;
  left: ${props => (props.align == 'left' ? '0' : 'auto')};
  right: ${props => (props.align == 'right' ? '0' : 'auto')};
  color: ${colors.mediumGrey};
  pointer-events: none;
  color: ${colors.mediumGrey};
`;

const ValueBar = styled.div`
  position: absolute;
  width: ${props => props.value}%;
  height: 4px;
  background-color: ${colors.darkRed};
  border-radius: 4px;
  top: 0;
  pointer-events: none;
  margin: 25px 0;
`;

class RangeSliderOld extends React.Component {
  static propTypes = {
    range: PropTypes.object,
    value: PropTypes.number,
    onChange: PropTypes.func,
    leftLabel: PropTypes.string,
    rightLabel: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
  }

  handleChange() {
    const { onChange } = this.props;
    const value = parseInt(this.inputRef.current.value);
    onChange(value);
  }

  render() {
    const { range, value, leftLabel, rightLabel } = this.props;
    const fullRange = range.to - range.from;
    const barProgress = (100 / fullRange) * (value - range.from);
    return (
      <Container>
        <Value align="left">{leftLabel}</Value>
        <RangeContainer>
          <ValueBar value={barProgress} />
          <InputRange ref={this.inputRef} onChange={this.handleChange.bind(this)} step="1" min={range.from} max={range.to} type="range" defaultValue={value} />
        </RangeContainer>
        <Value align="right">{rightLabel}</Value>
      </Container>
    );
  }
}

export default hot(module)(RangeSliderOld);
