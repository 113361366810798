import React from 'react';
import { hot } from 'react-hot-loader';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from '../../styles';

const Svg = styled.svg`
  transform: rotate(${props => props.rotation}deg);
  padding-left: 2px;
  margin: -8px -4px;
`;

class Arrow extends React.Component {
  static propTypes = {
    pointRight: PropTypes.bool,
    dark: PropTypes.bool,
    direction: PropTypes.string,
  };

  render() {
    const { dark, direction } = this.props;
    const arrowColor = dark ? colors.darkGrey : 'white';
    const arrowRotation = direction == 'down' ? 90 : direction == 'left' ? 180 : direction == 'up' ? -90 : 0;
    return (
      <Svg width={9} height={16} rotation={arrowRotation}>
        <path stroke={arrowColor} strokeWidth="3" fill="none" d="M1 1 L7 8 L1 15" />
      </Svg>
    );
  }
}

export default hot(module)(Arrow);
