import React from 'react';
import { hot } from 'react-hot-loader';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export const Container = styled.div`
  color: #6e6e6e;
`;

export const Ul = styled.ul`
  margin: 25px 0 0;
  padding-left: 17px;
`;

export const Li = styled.li`
  padding: 5px 0 5px 15px;
`;

class HotSpotModal extends React.Component {
  static propTypes = {
    listItems: PropTypes.array,
  };

  renderListItems(items) {
    return items.map((txt, index) => <Li key={index}>{txt}</Li>);
  }

  render() {
    const { listItems } = this.props;
    return (
      <Container>
        <Ul>{this.renderListItems(listItems)}</Ul>
      </Container>
    );
  }
}

export default hot(module)(HotSpotModal);
