export const STEP_TYPE_LOADING = 'NAVIGATION.STEP_TYPE_LOADING';
export const STEP_TYPE_IDLE = 'NAVIGATION.STEP_TYPE_IDLE';
export const STEP_TYPE_INTRO = 'NAVIGATION.STEP_TYPE_INTRO';
export const STEP_TYPE_INFO = 'NAVIGATION.STEP_TYPE_INFO';
export const STEP_TYPE_INVITE = 'NAVIGATION.STEP_TYPE_INVITE';
export const STEP_TYPE_VIDEOTEST = 'NAVIGATION.STEP_TYPE_VIDEOTEST';

export const INFO_TYPE_REGULAR = 'NAVIGATION.INFO_TYPE_REGULAR';
export const INFO_TYPE_RANGE = 'NAVIGATION.INFO_TYPE_RANGE';
export const INFO_TYPE_CHARGING = 'NAVIGATION.INFO_TYPE_CHARGING';
export const INFO_TYPE_BATTERY = 'NAVIGATION.INFO_TYPE_BATTERY';

export const HOTSPOT_TYPE_SINGLE = 'NAVIGATION.HOTSPOT_TYPE_SINGLE';
export const HOTSPOT_TYPE_MULTI = 'NAVIGATION.HOTSPOT_TYPE_MULTI';
export const HOTSPOT_TYPE_COMPARISON = 'NAVIGATION.HOTSPOT_TYPE_COMPARISON';
export const HOTSPOT_TYPE_MAP = 'NAVIGATION.HOTSPOT_TYPE_MAP';
export const HOTSPOT_TYPE_VIDEO = 'NAVIGATION.HOTSPOT_TYPE_VIDEO';

export const GO_TO_INDEX = 'NAVIGATION.GO_TO_INDEX';
export const RETURN_TO_IDLE = 'NAVIGATION.RETURN_TO_IDLE';
export const SELECT_IMAGE_INDEX = 'NAVIGATION.SELECT_IMAGE_INDEX';

export const SELECT_LANGUAGE = 'NAVIGATION.SELECT_LANGUAGE';
