import React from 'react';
import { hot } from 'react-hot-loader';
import styled, { css } from 'styled-components';
import { colors, H2 } from '../../styles';
import PropTypes from 'prop-types';
import { TransitionGroup, Transition } from 'react-transition-group';

const Container = styled.div`
  position: absolute;
  width: 425px;
  height: 90px;
  left: 50%;
  bottom: 100px;
  text-align: center;
  padding: 11px 0;
  background-color: ${props => (props.red ? colors.darkRed : 'white')};
  color: black;
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  user-select: none;
  transition: background-color 0.2s linear, transform 1s cubic-bezier(0.1, 0.67, 0.38, 1), opacity 0.5s linear;
  ${props => {
    switch (props.transitionState) {
      case 'entering':
        return buttonEntering;
      case 'entered':
        return buttonEntered;
      case 'exiting':
      case 'exited':
      default:
        return buttonExited;
    }
  }}
`;

const buttonEntering = css`
  opacity: 0;
  transform: translate(-50%, 70px);
`;

const buttonEntered = css`
  opacity: 1;
  transform: translate(-50%, 0);
`;

const buttonExited = css`
  opacity: 0;
  transform: translate(-50%, -70px);
  transition: background-color 0.2s linear, transform 0.7s ease-in, opacity 0.5s linear;
`;

const Title = styled(H2)`
  position: absolute;
  width: 100%;
  color: ${props => (props.red ? 'white' : colors.darkGrey)};
  transition: all 0.2s linear;

  ${props => {
    switch (props.transitionState) {
      case 'entering':
        return titleEntering;
      case 'entered':
        return titleEntered;
      case 'exiting':
      case 'exited':
      default:
        return titleExited;
    }
  }}
`;

const titleEntering = css`
  opacity: 0;
  transform: translateY(-10px);
`;

const titleEntered = css`
  opacity: 1;
  transform: none;
`;

const titleExited = css`
  opacity: 0;
  transform: translateY(10px);
`;

class StartButton extends React.Component {
  static propTypes = {
    onClick: PropTypes.func,
    title: PropTypes.string,
    transitionState: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.toggleRed = this.toggleRed.bind(this);
    this.state = { red: false, timestamp: 0 };
    this.toggleRedTimeout = null;
    this.redTime = 1500;
    this.whiteTime = 4500;
  }

  componentDidMount() {
    this.toggleRedTimeout = window.setTimeout(this.toggleRed, this.whiteTime);
  }

  toggleRed() {
    if (this.state.red) {
      this.toggleRedTimeout = window.setTimeout(this.toggleRed, this.whiteTime);
    } else {
      this.toggleRedTimeout = window.setTimeout(this.toggleRed, this.redTime);
    }
    this.setState({ red: !this.state.red });
  }

  componentWillUnmount() {
    window.clearTimeout(this.toggleRedTimeout);
  }

  render() {
    const { onClick, title, transitionState } = this.props;
    const { red } = this.state;

    return (
      <Container
        transitionState={transitionState}
        red={red}
        onClick={() => {
          onClick();
        }}
      >
        <TransitionGroup>
          <Transition appear timeout={200} key={red}>
            {transitionState => (
              <Title transitionState={transitionState} red={red}>
                {title}
              </Title>
            )}
          </Transition>
        </TransitionGroup>
      </Container>
    );
  }
}

export default hot(module)(StartButton);
