import React from 'react';
import { hot } from 'react-hot-loader';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { TransitionGroup, Transition } from 'react-transition-group';
import QRModal from './QRModal';

const Container = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: ${props => (props.show ? 'auto' : 'none')};
`;

const Dark = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: black;
  transition: opacity 1s;
  will-change: opacity;
  opacity: ${props => (props.show ? 0.5 : 0)};
`;

class QRModalContainer extends React.Component {
  static propTypes = {
    qrLink: PropTypes.string,
    headline: PropTypes.string,
    copy: PropTypes.string,
    close: PropTypes.func,
    showModal: PropTypes.bool,
  };

  renderModalTransitionGroup() {
    const { headline, copy, qrLink, close } = this.props;
    return (
      <TransitionGroup component={null}>
        <Transition appear timeout={0}>
          {transitionState => <QRModal transitionState={transitionState} headline={headline} copy={copy} qrLink={qrLink} close={close} />}
        </Transition>
      </TransitionGroup>
    );
  }

  render() {
    const { showModal, close } = this.props;
    return (
      <Container show={showModal}>
        <Dark show={showModal} onClick={close} />
        {showModal && this.renderModalTransitionGroup()}
      </Container>
    );
  }
}

export default hot(module)(QRModalContainer);
