import React from 'react';
import { languages } from '../../constants/dataConstants';
import { hot } from 'react-hot-loader';
import PropTypes from 'prop-types';
import {
  LanguageListItem,
  OverlayTopline,
  OverlayHeadline,
  OverlaySubline,
  OverlayBottomText,
  Container,
  VideoPoster,
  Video,
  LanguageButton,
  LanguageSelectionBar,
  LanguageList,
  CurrentLanguage,
  CloseLanguagesButton,
} from './IdleStep.style';
import StartButton from '../input/StartButton';
import { TransitionGroup, Transition } from 'react-transition-group';
import Curtain from '../misc/Curtain';

class IdleStep extends React.Component {
  constructor(props) {
    super(props);
    this.videoRef = React.createRef();
    this.openlanguageSelection = this.openlanguageSelection.bind(this);
    this.closelanguageSelection = this.closelanguageSelection.bind(this);
    this.renderLanguageList = this.renderLanguageList.bind(this);
    this.onVideoCanPlay = this.onVideoCanPlay.bind(this);
    this.onClick = this.onClick.bind(this);

    this.SetVideoTime = this.SetVideoTime.bind(this);
    this.renderTextOverlays = this.renderTextOverlays.bind(this);

    this.state = {
      videoReady: false,
      outTransitionStarted: false,
      languageSelectionOpen: false,
      overlayTopLine: null,
      overlayHeadline: null,
      overlayEndclaim: null,
    };
    this.outTransitionTimeout = null;
    this.animationFrameRequestId = null;

    this.outTransitionDuration = 1600;
  }

  static propTypes = {
    goToStepIndex: PropTypes.func,
    startOutTransition: PropTypes.func,
    allLanguages: PropTypes.array,
    currentLanguage: PropTypes.string,
    selectLanguage: PropTypes.func,
    stageContent: PropTypes.object,
  };

  openlanguageSelection() {
    this.setState({ languageSelectionOpen: true });
  }

  closelanguageSelection() {
    this.setState({ languageSelectionOpen: false });
  }

  onVideoCanPlay() {
    this.setState({ videoReady: true });
    this.videoRef.current.play();
  }

  componentDidMount() {
    this.videoRef.current.addEventListener('canplaythrough', this.onVideoCanPlay);
    this.animationFrameRequestId = requestAnimationFrame(this.SetVideoTime);
  }

  onClick() {
    if (this.outTransitionTimeout === null) {
      this.setState({ outTransitionStarted: true });
      this.closelanguageSelection();
      this.outTransitionTimeout = window.setTimeout(() => {
        this.props.goToStepIndex(1);
      }, this.outTransitionDuration);
    }
    this.props.startOutTransition();
  }

  componentWillUnmount() {
    window.clearTimeout(this.outTransitionTimeout);
    this.videoRef.current.removeEventListener('canplaythrough', this.onVideoCanPlay);
    window.cancelAnimationFrame(this.animationFrameRequestId);
  }

  renderLanguageList(languageHandles, currentLanguage) {
    return languageHandles
      .sort((a, b) => this.GetLocalizationFromLanguageHandle(a.language).localeCompare(this.GetLocalizationFromLanguageHandle(b.language)))
      .map((lh, index) => {
        return (
          <LanguageListItem
            key={index}
            selected={lh.language === currentLanguage}
            onClick={() => {
              this.props.selectLanguage(lh.language);
              this.closelanguageSelection();
            }}
          >
            {this.GetLocalizationFromLanguageHandle(lh.language)}
          </LanguageListItem>
        );
      });
  }

  GetLocalizationFromLanguageHandle(handle) {
    const lang = languages.find(lang => lang.handle === handle);
    const localization = lang ? lang.localization : 'Unknown Language';
    return localization;
  }

  SetVideoTime() {
    const { idleSuper } = this.props.stageContent;
    if (this.videoRef.current != null) {
      const videoTime = this.videoRef.current.currentTime;
      const curOverlayTopline = idleSuper.find(t => t.type === 'topline' && videoTime * 25 >= t.startFrame && videoTime * 25 <= t.endFrame);
      const curOverlayHeadline = idleSuper.find(t => t.type === 'headline' && videoTime * 25 >= t.startFrame && videoTime * 25 <= t.endFrame);
      const curOverlayEndClaim = idleSuper.find(t => t.type === 'endClaim' && videoTime * 25 >= t.startFrame && videoTime * 25 <= t.endFrame);
      if (curOverlayTopline !== this.state.overlayTopLine) this.setState({ overlayTopLine: curOverlayTopline });
      if (curOverlayHeadline !== this.state.overlayHeadline) this.setState({ overlayHeadline: curOverlayHeadline });
      if (curOverlayEndClaim !== this.state.overlayEndclaim) this.setState({ overlayEndclaim: curOverlayEndClaim });
    }
    this.animationFrameRequestId = requestAnimationFrame(this.SetVideoTime);
  }

  renderTextOverlays() {
    const { overlayTopLine, overlayHeadline, overlayEndclaim } = this.state;
    return (
      <>
        {overlayTopLine && <OverlayTopline>{overlayTopLine.topline}</OverlayTopline>}
        {overlayHeadline && <OverlayHeadline>{overlayHeadline.headline}</OverlayHeadline>}
        {overlayEndclaim && this.renderEndclaimOverlays(overlayEndclaim)}
      </>
    );
  }

  renderEndclaimOverlays(endclaim) {
    return (
      <>
        <OverlayHeadline moveUp>{endclaim.headline}</OverlayHeadline>
        <OverlaySubline>{endclaim.subline}</OverlaySubline>
        <OverlayBottomText>{endclaim.bottomText}</OverlayBottomText>
      </>
    );
  }

  render() {
    const { allLanguages, stageContent, currentLanguage } = this.props;
    const { videoReady, outTransitionStarted, languageSelectionOpen } = this.state;
    const { idleVideo } = stageContent;
    const startButtonText = stageContent.startbutton;
    const currentLanguageLocalization = this.GetLocalizationFromLanguageHandle(this.props.currentLanguage);

    return (
      <Container>
        <VideoPoster onClick={this.closelanguageSelection} />
        <Video videoReady={videoReady} ref={this.videoRef} loop muted preload="auto" onClick={this.closelanguageSelection} src={idleVideo} type="video/mp4" />
        {this.renderTextOverlays()}
        <LanguageButton onClick={this.openlanguageSelection}>
          {currentLanguageLocalization}
          <svg height="10" width="16" fill="white">
            <path d="M0 0 L16 0 L8 10 Z" />
          </svg>
        </LanguageButton>
        <LanguageSelectionBar open={languageSelectionOpen}>
          <CurrentLanguage>{currentLanguageLocalization}</CurrentLanguage>
          <CloseLanguagesButton onClick={this.closelanguageSelection}>
            <div className="cross">
              <div className="one" />
              <div className="two" />
            </div>
          </CloseLanguagesButton>
          <LanguageList
            onTouchMove={evt => {
              evt.stopPropagation();
            }}
            onTouchEnd={evt => {
              evt.stopPropagation();
            }}
          >
            {this.renderLanguageList(allLanguages, currentLanguage)}
          </LanguageList>
        </LanguageSelectionBar>
        <TransitionGroup>
          <Transition appear timeout={1000}>
            {transitionState => {
              if (outTransitionStarted) transitionState = 'exiting';
              return <StartButton transitionState={transitionState} onClick={this.onClick} title={startButtonText} />;
            }}
          </Transition>
        </TransitionGroup>
        <Curtain down={outTransitionStarted} transitionDuration={800} transitionDelay={800} />
      </Container>
    );
  }
}

export default hot(module)(IdleStep);
