import React from 'react';
import { hot } from 'react-hot-loader';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: black;
  pointer-events: none;
  opacity: ${props => (props.down ? 1 : 0)};
  transition: opacity ${props => props.transitionDuration}ms linear ${props => props.transitionDelay}ms;
  transform: scale(${props => props.scaleFactor});
`;

class Curtain extends React.Component {
  static propTypes = {
    down: PropTypes.bool,
    transitionDuration: PropTypes.number,
    transitionDelay: PropTypes.number,
    scaleFactor: PropTypes.number,
  };

  render() {
    const { down, transitionDuration, transitionDelay, scaleFactor } = this.props;
    return <Container down={down} transitionDuration={transitionDuration} transitionDelay={transitionDelay} scaleFactor={scaleFactor}></Container>;
  }
}

Curtain.defaultProps = { transitionDelay: 0, scaleFactor: 1 };

export default hot(module)(Curtain);
