import React from 'react';
import { hot } from 'react-hot-loader';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Dot = styled.div`
  background-color: white;
  width: 26px;
  height: 26px;
  position: absolute;
  left: 4px;
  top: 6px;
  border: 6px solid rgba(255, 255, 255, 0.2);
  background-clip: padding-box;
  border-radius: 50%;
  transition: transform, opacity;
  transition-timing-function: ${props => (props.visible ? 'cubic-bezier(0.42, 1.4, 0.66, 1)' : 'cubic-bezier(.29,.01,.51,-0.32)')};
  transition-duration: 0.5s;//${props => (props.visible ? 0.5 : 0.25)}s;
  transform: scale(${props => (props.visible ? 1 : 0)});
  transition-delay: ${props => (props.visible ? 0 : 0)}s;
  opacity: ${props => (props.visible ? 1 : 0)};
`;

const Cross = styled.div`
  width: 100%;
  height: 100%;
  transition: transform 0.7s ease-out;
  transform: ${props => (props.visible ? `rotate(${props.isCloseButton ? -45 : 0}deg)` : 'rotate(-225deg)')};
`;

const CrossLine = styled.div`
  width: 8px;
  height: 2px;
  background-color: black;
  position: absolute;
  left: 9px;
  top: 12px;
  transform: ${props => (props.second ? 'rotate(90deg)' : 'none')};
`;

const PlayIcon = styled.svg`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 9px 10px;
`;

class PlusDot extends React.Component {
  static propTypes = {
    visible: PropTypes.bool,
    handleClick: PropTypes.func,
    type: PropTypes.string,
    isVideoHotspot: PropTypes.bool,
  };

  handleClick() {
    this.props.handleClick();
  }

  render() {
    const { visible, type, isVideoHotspot } = this.props;
    const isCloseButton = type == 'close';
    return (
      <Dot visible={visible}>
        {!isVideoHotspot && (
          <Cross visible={visible} isCloseButton={isCloseButton}>
            <CrossLine />
            <CrossLine second />
          </Cross>
        )}
        {isVideoHotspot && (
          <PlayIcon>
            <polygon points="0,0 8,4 0,8" style={{ fill: 'black' }} />
          </PlayIcon>
        )}
      </Dot>
    );
  }
}

export default hot(module)(PlusDot);
