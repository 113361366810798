import React from 'react';
import { hot } from 'react-hot-loader';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors, navigationBarHeight, Label } from '../../styles';

const Container = styled.div`
  position: relative;
  display: inline-block;
  width: 200px;
  height: ${navigationBarHeight}px;
  cursor: pointer;
  margin-top: 10px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
`;

const Caption = styled(Label)`
  position: absolute;
  width: 100%;
  color: ${props => (props.highlight ? 'white' : colors.mediumGrey)};
  margin-top: 30px;
  transform: ${props => (props.highlight ? 'translateY(-5px)' : 'none')};
  transition: all 450ms cubic-bezier(0.56, 1.49, 0.65, 1.01) ${props => props.index * 30 - 150}ms;
  backface-visibility: hidden;
`;

const Dot = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 13px;
  height: 13px;
  border-width: 3px;
  border-style: solid;
  border-color: ${props => (props.highlight ? 'white' : colors.mediumGrey)};
  border-radius: 50%;
  background: ${colors.darkGrey};
  transition: border-color 300ms ease-out;
`;

class StepNavigationDot extends React.Component {
  static propTypes = {
    active: PropTypes.bool,
    highlight: PropTypes.bool,
    index: PropTypes.number,
    caption: PropTypes.string,
    goToStepIndex: PropTypes.func,
  };

  render() {
    const { active, index, caption, highlight } = this.props;
    return (
      <Container
        onClick={() => {
          if (!active) this.props.goToStepIndex(index);
        }}
      >
        <Dot highlight={highlight} />
        <Caption highlight={highlight} index={index}>
          {caption}
        </Caption>
      </Container>
    );
  }
}

export default hot(module)(StepNavigationDot);
