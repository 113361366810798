import React from 'react';
import { hot } from 'react-hot-loader';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import HomeIcon from '../../assets/images/icons/home.png';

const Container = styled.div`
  position: absolute;
  left: 80px;
  bottom: 37px;
  cursor: pointer;
`;

class HomeButton extends React.Component {
  static propTypes = {
    returnToHome: PropTypes.func,
  };

  render() {
    return (
      <Container onClick={this.props.returnToHome}>
        <img src={HomeIcon} />
      </Container>
    );
  }
}

export default hot(module)(HomeButton);
