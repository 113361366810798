import * as DATA from '../constants/dataConstants';
import * as NAVIGATION from '../constants/navigationConstants.js';
import { getSteps, getTexts } from '../services/DataService';
import { loadValue } from '../services/LocalStorageService';

function getAvailableLangeFromResponse(language, response) {
  let languageIsInList = false;
  response.forEach(element => {
    if (element.language === language) languageIsInList = true;
  });
  if (typeof language === 'undefined' || !languageIsInList) {
    language = 'mmuk';
    // eslint-disable-next-line no-console
    console.log('no language in local storage or language not in list of loaded languages, using default: ' + language);
  } else {
    // eslint-disable-next-line no-console
    console.log('load language from local storage: ' + language);
  }
  return language;
}

export function fetchAll() {
  return dispatch => {
    const language = loadValue('language');
    dispatch({ type: DATA.DATA_INIT });
    return Promise.all([
      getSteps().then(response => {
        dispatch({ type: DATA.DATA_STEPS_LOAD_FULFILLED, payload: response });
        return getAvailableLangeFromResponse(language, response);
      }),
      getTexts().then(response => {
        dispatch({ type: DATA.DATA_TEXTS_LOAD_FULFILLED, payload: response });
      }),
    ])
      .then(([stepLanguage]) => {
        dispatch({
          type: NAVIGATION.SELECT_LANGUAGE,
          payload: stepLanguage,
        });
        dispatch({ type: DATA.DATA_LOAD_FULFILLED });
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.error(error);
        dispatch({ type: DATA.DATA_LOAD_REJECTED, payload: error });
      });
  };
}
