import React from 'react';
import { hot } from 'react-hot-loader';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import StageImage from './StageImage';

const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  transform: ${props => (props.loaded ? 'scale(1.005)' : 'scale(1.08)')};
  transition: transform 2000ms cubic-bezier(0.15, 0.68, 0.3, 1);
`;

class StageImageBox extends React.Component {
  static propTypes = {
    images: PropTypes.array,
    fallbackImage: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = { loaded: false, images: [] };
    this.onLoad = this.onLoad.bind(this);
    this.cleanUpImages = this.cleanUpImages.bind(this);
    this.cleanUpImagesTimeOut = null;
    this.renderImages = this.renderImages.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    let newSelectedImage = props.images[0].url;
    if (props.images.length > 1) newSelectedImage = props.images.find(image => image.selected).url;
    const newImages = [...state.images];
    if (newImages.length === 0 || newSelectedImage != newImages[newImages.length - 1].url) {
      newImages.push({ url: newSelectedImage, key: Date.now() });
      if (newImages.length > 4) newImages.shift();
    }
    return { images: newImages };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.images[prevState.images.length - 1].key !== this.state.images[this.state.images.length - 1].key) {
      window.clearTimeout(this.cleanUpImagesTimeOut);
      this.cleanUpImagesTimeOut = window.setTimeout(this.cleanUpImages, 700);
    }
  }

  componentWillUnmount() {
    window.clearTimeout(this.cleanUpImagesTimeOut);
  }

  cleanUpImages() {
    const images = [this.state.images[this.state.images.length - 1]];
    this.setState({ images });
  }

  onLoad() {
    this.setState({ loaded: true });
  }

  renderImages() {
    return this.state.images.map(image => <StageImage onLoad={this.onLoad} key={image.key} image={image.url} fallbackImage={this.props.fallbackImage} />);
  }

  render() {
    return <Container loaded={this.state.loaded}>{this.renderImages()}</Container>;
  }
}

export default hot(module)(StageImageBox);
