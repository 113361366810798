import * as NAVIGATION from '../../constants/navigationConstants';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import HotSpot from './HotSpot';
import HotSpotModal from './HotSpotModal';
import HotSpotVideoModal from './HotSpotVideoModal';
import HotSpotMultiModal from './HotSpotMultiModal';
import HotSpotComparisonModal from './HotSpotComparisonModal';
import HotSpotMapModal from './HotSpotMapModal';
import { TransitionGroup, Transition } from 'react-transition-group';
import { getOpenChargingModal } from '../../selectors/interactionSelectors';
import { closeChargingModal, toggleInteriorModal } from '../../actions/interActions';

const Container = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const Dark = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: black;
  transition: opacity 1s;
  will-change: opacity;
  opacity: ${props => (props.show ? 0.5 : 0)};
`;

const checkHotspotLoadability = hotspot =>
  new Promise(resolve => {
    if (!hotspot.video) {
      return resolve(true);
    }
    const video = document.createElement('video');
    video.src = hotspot.video;
    video.addEventListener('loadeddata', () => resolve(true));
    video.addEventListener('error', () => resolve(false));
  });

class HotSpotContainer extends React.Component {
  static propTypes = {
    hotSpots: PropTypes.array,
    stageImageIndex: PropTypes.number,
    fadeOut: PropTypes.bool,
    currentLanguage: PropTypes.string,
    toggleInteriorModal: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      hotSpots: this.props.hotSpots,
      activeHotSpotIndex: null,
    };
    this.renderModal = this.renderModal.bind(this);
  }

  closeModal() {
    this.props.toggleInteriorModal(false);
    this.setState({ activeHotSpotIndex: null });
  }

  handleHotSpotSelect(index) {
    this.props.toggleInteriorModal(true);
    this.setState({ activeHotSpotIndex: index });
  }

  componentDidMount() {
    this.removeUnloadableVideoHotspots();
  }

  componentDidUpdate(prevProps) {
    if (this.props.hotSpots !== prevProps.hotSpots) {
      this.removeUnloadableVideoHotspots();
    }
  }

  removeUnloadableVideoHotspots() {
    Promise.all(this.props.hotSpots.map(checkHotspotLoadability)).then(loadedHotspots => {
      const hotSpots = this.props.hotSpots.filter((h, i) => loadedHotspots[i]);
      this.setState({ hotSpots });
    });
  }

  componentWillUnmount() {
    this.props.toggleInteriorModal(false);
  }

  renderHotSpots(hotSpots, activeHotSpotIndex, hotSpotsVisible, stageImageIndex) {
    return hotSpots.map((hotSpot, index) => {
      let position = hotSpot.positions.length > 0 ? hotSpot.positions[Math.min(stageImageIndex, hotSpot.positions.length - 1)] : { x: 50, y: 50 };
      if (position.x === 0 && position.y === 0) position = { x: 50, y: 50 };
      const hasTitle = !!hotSpot.title;
      return (
        <HotSpot
          key={hotSpot.title + index + stageImageIndex}
          title={hotSpot.title}
          position={position}
          index={index}
          isVideoHotspot={hotSpot.type === NAVIGATION.HOTSPOT_TYPE_VIDEO}
          active={activeHotSpotIndex == index}
          handleSelect={this.handleHotSpotSelect.bind(this)}
          visible={hotSpotsVisible && stageImageIndex === 0 && hasTitle}
          startDelay={index * 150}
        />
      );
    });
  }

  renderModal(transitionState, hotSpot) {
    switch (hotSpot.type) {
      case NAVIGATION.HOTSPOT_TYPE_SINGLE:
        return <HotSpotModal close={this.closeModal.bind(this)} transitionState={transitionState} key={hotSpot.title} hotSpot={hotSpot} />;
      case NAVIGATION.HOTSPOT_TYPE_VIDEO:
        return <HotSpotVideoModal close={this.closeModal.bind(this)} transitionState={transitionState} key={hotSpot.title} hotSpot={hotSpot} />;
      case NAVIGATION.HOTSPOT_TYPE_MULTI:
        return <HotSpotMultiModal close={this.closeModal.bind(this)} transitionState={transitionState} key={hotSpot.title} hotSpot={hotSpot} />;
      case NAVIGATION.HOTSPOT_TYPE_COMPARISON:
        return <HotSpotComparisonModal close={this.closeModal.bind(this)} transitionState={transitionState} key={hotSpot.title} hotSpot={hotSpot} />;
      case NAVIGATION.HOTSPOT_TYPE_MAP:
        return (
          <HotSpotMapModal
            close={this.closeModal.bind(this)}
            currentLanguage={this.props.currentLanguage}
            transitionState={transitionState}
            key={hotSpot.title}
            hotSpot={hotSpot}
          />
        );
      default:
        return <HotSpotModal close={this.closeModal.bind(this)} transitionState={transitionState} key={hotSpot.title} hotSpot={hotSpot} />;
    }
  }

  renderModalTransitionGroup(hotSpot, activeHotSpotIndex) {
    if (activeHotSpotIndex === null) return;
    return (
      <TransitionGroup component={null}>
        <Transition appear timeout={500}>
          {transitionState => this.renderModal(transitionState, hotSpot)}
        </Transition>
      </TransitionGroup>
    );
  }

  render() {
    const { activeHotSpotIndex, hotSpots } = this.state;
    const { stageImageIndex, fadeOut } = this.props;
    const showDarkBackgroundAndHideHotSpots = activeHotSpotIndex != null;
    return (
      <Container>
        <Dark show={showDarkBackgroundAndHideHotSpots} onClick={this.closeModal.bind(this)} />
        {this.renderHotSpots(hotSpots, activeHotSpotIndex, !showDarkBackgroundAndHideHotSpots && !fadeOut, stageImageIndex)}
        {this.renderModalTransitionGroup(hotSpots[activeHotSpotIndex], activeHotSpotIndex)}
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  openChargingModal: getOpenChargingModal(state),
  currentLanguage: state.navigation.currentLanguage,
});

export default connect(
  mapStateToProps,
  { closeChargingModal, toggleInteriorModal }
)(HotSpotContainer);
