import React from 'react';
import { hot } from 'react-hot-loader';
import PropTypes from 'prop-types';
import PlusDot from './PlusDot';
import { Container, CloseButtonContainer } from './Modal.style';
import styled from 'styled-components';
import HotSpotModalTextBlock from './HotSpotModalTextBlock';
import QRLink from '../misc/QRLink';

export const TopContainer = styled.div`
  height: 343px;
  overflow: hidden;
  position: relative;
  background-color: #e7e7e7;
`;

export const TextBlockContainer = styled.div`
  text-align: center;
  padding: 42px 57px 60px;
`;

const QrContainer = styled.div`
  position: absolute;
  display: inline-block;
  background: white;
  padding: 10px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

class QRModal extends React.Component {
  static propTypes = {
    qrLink: PropTypes.string,
    headline: PropTypes.string,
    copy: PropTypes.string,
    transitionState: PropTypes.string,
    close: PropTypes.func,
  };

  render() {
    const { qrLink, headline, copy, transitionState, close } = this.props;
    return (
      <Container transitionState={transitionState}>
        <TopContainer>
          <QrContainer>
            <QRLink url={qrLink} size={200} />
          </QrContainer>
        </TopContainer>
        <TextBlockContainer>
          <HotSpotModalTextBlock headline={headline} copy={copy} />
        </TextBlockContainer>
        <CloseButtonContainer onClick={close}>
          <PlusDot visible={transitionState == 'entered'} type="close" />
        </CloseButtonContainer>
      </Container>
    );
  }
}

export default hot(module)(QRModal);
