import { combineReducers } from 'redux';
import navigationReducer from './navigationReducer';
import interactionReducer from './interactionReducer';
import textReducer from './textReducer';

const reducers = combineReducers({
  navigation: navigationReducer,
  text: textReducer,
  interaction: interactionReducer,
});

export default reducers;
